import { Modal } from '@f/core/Modal'
import MobileRelatedProductsModalComponent from './MobileRelatedProductsModal.vue'

class MobileRelatedProductsModal extends Modal {
	component = MobileRelatedProductsModalComponent
	title = 'Dodano produkt do koszyka'

	constructor (product) {
		super()
		this.product = product
	}
}

export { MobileRelatedProductsModal }
