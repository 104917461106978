<template>
	<div class="row" :class="{'row-reverse': reverse}">
		<slot />
	</div>
</template>

<script>
import BaseRow from '@f/components/BaseRow'
export default {
	extends: BaseRow,
	name: 'BaseRow',
	globalComponent: true
}
</script>
<style lang="scss" scoped>
@mixin make-row($gutter: $grid-gutter-width) {
	display: flex;
	flex: 1 0 100%;
	flex-wrap: wrap;
	margin-right: math.div(-$gutter, 2);
	margin-left: math.div(-$gutter, 2);
}

.row {
	@include make-row();
	&-reverse {
		flex-direction: column-reverse;
	}
	@include media-breakpoint-up(lg) {
		&-reverse {
			flex-direction: row-reverse;
		}
	}
}
</style>
