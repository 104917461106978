<template>
	<div class="related-product-modal">
		<slim-products-list modal :products="modal.product.relatedProducts" />
	</div>
</template>

<script>
import SlimProductsList from '~/shop/front/components/SlimProductsList/SlimProductsList.vue'

export default {
	props: {
		modal: Object
	},
	components: {
		SlimProductsList
	}
}
</script>
<style lang="scss" scoped>
.related-product-modal {
	max-height: 80vh;
	:deep(.product-list-content) {
		padding-bottom: 3rem;
	}
}
</style>
