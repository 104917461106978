<template>
	<div id="inpost-map"></div>
</template>

<script>
export default {
	props: {
		modal: Object
	}
}
</script>

<style lang="scss" scoped>
@import url('https://geowidget.easypack24.net/css/easypack.css');

#inpost-map {
	@include media-breakpoint-up(md) {
		padding-bottom: 3rem;
	}

	@media screen and (max-height: 376px) {
		.map-widget {
			height: 100%;
		}
		.list-widget {
			min-height: 0;
		}

		.map-list-row {
			min-height: 0;
		}
		.map-list-flex {
			min-height: 0;
		}
	}

	.search-widget {
		background: white;
		border: 1px solid black;
		z-index: $level3;

		.search-group {
			padding: 0;
		}

		.map-widget {
			margin-top: 1px;
		}
	}
}
</style>
