<template>
	<div class="product-list-content">
		<div class="products-wrapper" :class="{modal, horizontalSlider}">
			<product-list-item v-for="product in products" :key="product.id" :product="product" :horizontalSlider="horizontalSlider" slim />
		</div>
	</div>
</template>

<script>
import ProductListItem from '~/shop/front/components/ProductListItem'

export default {
	props: {
		products: Array,
		modal: Boolean,
		horizontalSlider: Boolean
	},
	components: {
		ProductListItem
	}
}
</script>
<style lang="scss" scoped>
.products-wrapper {
	display: grid;
	grid-template-columns: 1fr;
	grid-gap: 2rem;
	margin-bottom: 4rem;
	&.horizontalSlider {
		display: flex;
		width: 100%;
		overflow: scroll;
		padding: 2rem;
	}
	@include media-breakpoint-up(md) {
		grid-template-columns: 1fr 1fr;
	}
	@include media-breakpoint-up(lg) {
		grid-template-columns: 1fr 1fr 1fr;
		&.modal {
			grid-template-columns: 1fr 1fr 1fr;
		}
	}
	@include media-breakpoint-up(xl) {
		grid-template-columns: 1fr 1fr 1fr 1fr;
		margin-bottom: 8rem;
	}
}
</style>
