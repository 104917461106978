import { Layout } from '~/cms/front/core/layouts/Layout'
import component from './DefaultLayout.vue'

class DefaultLayout extends Layout {
	static alias = 'default'
	component = component

	get showContactSection () {
		return this.app.page.route.meta.alias !== 'contact'
	}

	get configuration () {
		return this.app.vue.$configuration
	}

	get discountData () {
		try {
			return this.app.settings.shop.product.globalDiscountInfo
		} catch (e) {
			console.log(e)
			return null
		}
	}

	get showGlobalDiscount () {
		try {
			return this.discountData?.type !== 'none' && (this.discountedProduct?.price !== this.discountedProduct?.priceBeforeDiscount || this.discountData.type === 'info')
		} catch (e) {
			return false
		}
	}

	async fetch () {
		if (this.ready) return true
		const data = await this.app.getService('rext').getLayout(this.alias)
		await this.fetchDiscountedProduct()
		this.value = data.value
		this.ready = true
		await this.init()
	}

	async fetchDiscountedProduct () {
		if (this.discountData?.type !== 'none') {
			const product = await this.app.getEntity('product').fetchOne(this.discountData?.value?.discountedProduct)
			this.discountedProduct = product
		}
	}

	async restore () {
		this.value = window.__LAYOUT__.value
		this.discountedProduct = window.__LAYOUT__.discountedProduct
		this.ready = true
		await this.init()
	}

	store () {
		return {
			value: this.value,
			discountedProduct: this.discountedProduct
		}
	}
}

export { DefaultLayout }
