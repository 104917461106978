<template>
	<div class="title-wrapper">
		<base-font variant="medium-header" tag="h3" weight="bold" class="title">
			<slot/>
		</base-font>
		<span class="line"></span>
		<button-close @close="close" class="btn-close" />
	</div>
</template>
<script>
import ButtonClose from '../ButtonClose/'
export default {
	components: { ButtonClose },
	methods: {
		close (e) {
			this.$emit('close', e)
		}
	}
}
</script>
<style lang="scss" scoped>
	.title-wrapper{
		display: flex;
        align-items: center;
		padding: 20px;

		@include media-breakpoint-up (lg) {
			padding: 30px;
		}

		.line {
			content: '';
			flex: 1;
			margin: 4px 20px 0 20px;
			height: 1px;
			background-color: $gray-600;
		}
		.btn-close{
			margin-top: 5px;
			z-index: $level1;
		}
		&:deep(.title) {
			margin: 0;
			font-size: 18px;
			font-family: $secondary-font;
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
			line-height: 1.2;

			@include media-breakpoint-up(xl) {
				font-size: 28px;
			}
		}
	}
	.safari .title-wrapper .line {
		margin: 4px 15px 0 15px;
	}
</style>
