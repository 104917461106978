import { RelatedProductsModal } from '../../components/RelatedProductsModal/RelatedProductsModal'
import { MobileRelatedProductsModal } from '../../components/MobileRelatedProductsModal/MobileRelatedProductsModal'

function productAddedToCart (app) {
	return function ({ product }) {
		if (window.innerWidth < 1200) {
			MobileRelatedProductsModal.createAndOpen(product)
			return true
		}
		if (product.relatedProducts) {
			RelatedProductsModal.createAndOpen(product)
			return true
		}
		app.cartModule.open()
	}
}

export { productAddedToCart }
