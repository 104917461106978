<script>
import { variants, tags, colors, fontFamily, sizes, weights } from '~/website/front/components/atoms/BaseFont'

export default {
	props: {
		variant: {
			type: String,
			default: 'paragraph',
			validator: (value) => {
				return Object.keys(variants).includes(value)
			}
		},
		tag: {
			type: String,
			default: '',
			validator: (value) => {
				return tags.includes(value)
			}
		},
		color: {
			type: String,
			default: 'primary',
			validator: (value) => {
				return colors.includes(value)
			}
		},
		family: {
			type: String,
			validator: (value) => {
				return fontFamily.includes(value)
			}
		},
		weight: {
			type: String,
			default: '',
			validator: (value) => {
				return weights.includes(value)
			}
		},
		size: {
			type: String,
			default: '',
			validator: (value) => {
				return sizes.includes(value)
			}
		},
		italic: {
			type: Boolean,
			default: false
		},
		uppercase: {
			type: Boolean,
			default: false
		},
		clear: {
			type: Boolean,
			default: false
		},
		html: {
			type: String
		},
		marginReset: {
			type: Boolean,
			default: false
		}
	},
	classes (props, data) {
		if (props.clear) return {}

		const chooser = type => props[type] ? props[type] : variants[props.variant][type]
		const color = chooser('color')
		const fontSize = chooser('size')
		const fontWeight = chooser('weight')
		const fontFamily = chooser('family')
		const uppercase = chooser('uppercase')
		const italic = chooser('italic')
		const marginReset = chooser('marginReset')

		const dynamicClasses = data.class || {}
		const staticClasses = data.staticClass ? data.staticClass.split(' ').reduce((final, item) => {
			final[item] = true
			return final
		}, {}) : {}

		return {
			[`fc-${color}`]: color,
			[`fs-${fontSize}`]: fontSize,
			[`fw-${fontWeight}`]: fontWeight,
			[`ff-${fontFamily}`]: fontFamily,
			fi: italic,
			fuc: uppercase,
			mr: marginReset,
			...staticClasses,
			...dynamicClasses
		}
	},
	tag (props) {
		if (props.clear) return 'span'

		const chooser = type => props[type] ? props[type] : variants[props.variant][type]
		return chooser('tag')
	}
}

</script>
