class MetaPixelService {
	static emitEvent (event, data = {}) {
		if (process.client) {
			window.fbq('track', event, data)
		} else {
			console.warn('Can not emit MetaPixel event from SSR!')
		}
	}
}

export { MetaPixelService }
