<template>
  <div id="app">
	<template v-if="$app.layout">
		<component v-if="$app.layout" :is="$app.layout.component">
			<component v-if="$app.page" :is="$app.page.component" />
		</component>
	</template>
	<component v-else-if="$app.page" :is="$app.page.component" />
	<base-modal/>
	<base-snackbar />
  </div>
</template>

<script>
import BaseModal from './components/BaseModal'
import BaseSnackbar from './components/BaseSnackbar'

export default {
	metaInfo: {
		script: [
			{
				src: '/browserDetect.js',
				type: 'text/javascript'
			}
		],
		meta: [
			{
				name: 'facebook-domain-verification',
				content: 'anumpa7lowr59p8d2xk1vl5r0izznu'
			}
		]
	},
	components: {
		BaseModal,
		BaseSnackbar
	}
}
</script>

<style lang="scss">
@import '@f/assets/scss/base/index.scss';

#app {
  font-family: 'Barlow', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
</style>
