import { getQuery } from 'utils/query'

export function rextServiceExtend (Service) {
	return class RextService extends Service {
		async getArticle (alias) {
			const { data, status } = await this.http.get(`/page/${alias}/article`)
			if (status !== 200) return { error: true }
			return data
		}

		async getArticles ({ take, skip = 0, where } = {}) {
			const query = getQuery({
				order: { datePublished: 'DESC' },
				take,
				skip,
				where,
				relations: ['author']
			})
			const { data, status } = await this.http.get(`/article${query}`)
			if (status !== 200) return { error: true }
			return data
		}

		async getArticlesLength () {
			const { data, status } = await this.http.get('/article/count')
			if (status !== 200) return { error: true }
			return data
		}
	}
}
