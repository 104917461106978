<template>
	<div class="global-discount">
		<div class="discount-info" v-if="discountType === 'discount'">
			<base-link to="product" class="product-link" :params="{alias: discountedProduct.alias}">
				<base-font variant="small-header" color="white" :key="hoursAndMinutesToEnd">Promocja kończy się za {{ daysToEnd }} dni {{ hoursAndMinutesToEnd }}</base-font>
				<base-font variant="small-header" color="white">{{ $app.layout.discountData.value.description }}</base-font>
			</base-link>
		</div>
		<div class="discount-info" v-else-if="discountType === 'info'">
			<base-font variant="small-header" color="white">{{ $app.layout.discountData.value.description }}</base-font>
		</div>
	</div>
</template>

<script>
export default {
	data: () => ({
		discountEndDate: null,
		daysToEnd: null,
		hoursAndMinutesToEnd: null,
		gmtModifier: null,
		hoursModifier: 1
	}),
	computed: {
		discountedProduct () {
			return this.$app.layout.discountedProduct
		},
		discountType () {
			return this.$app.layout.discountData.type
		}
	},
	methods: {
		getDiscountEndDate () {
			try {
				this.discountEndDate = new Date(new Date(this.discountedProduct.discountEndDate).getTime() - new Date(Date.now()).getTime())
				this.daysToEnd = this.discountEndDate.getDate() - 2
			} catch (e) {
				console.log(e)
				return ''
			}
		},
		getHoursAndMinutesToEnd () {
			setTimeout(() => {
				this.getDiscountEndDate()
				this.gmtModifier = this.discountEndDate.getTimezoneOffset()
				this.gmtModifier = this.gmtModifier / 60
				this.hoursAndMinutesToEnd = `${this.discountEndDate.getUTCHours() - this.gmtModifier - this.hoursModifier}:${this.discountEndDate.getUTCMinutes()}:${this.discountEndDate.getUTCSeconds()}`

				this.getHoursAndMinutesToEnd()
			}, 1000)
		}
	},
	created () {
		if (process.server) {
			this.hoursModifier = 2
		}

		if (this.discountType === 'discount') {
			this.getDiscountEndDate()
			this.gmtModifier = this.discountEndDate.getTimezoneOffset()
			this.gmtModifier = this.gmtModifier / 60
			this.hoursAndMinutesToEnd = `
			${this.discountEndDate.getUTCHours() - this.gmtModifier - this.hoursModifier}:${this.discountEndDate.getUTCMinutes()}:${this.discountEndDate.getUTCSeconds()}
		`

			this.getHoursAndMinutesToEnd()
		}
	}
}
</script>

<style scoped lang="scss">
.global-discount {
	width: 100%;
	background: $green-800;
	height: 50px;
	display: flex;
	justify-content: center;
	align-items: center;
	position: fixed;
	top: 0;
	z-index: $level2;

	.discount-info .product-link {
		display: block;
		text-align: center;
		gap: 1rem;
		& > * {
			margin: 0;
		}

		@include media-breakpoint-up(xl) {
			display: flex;
			text-align: left;
		}
	}
}
</style>
