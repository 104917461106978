<template functional>
	<div
		:ref="data.ref"
		:class="$options.classes(props, data)"
		:style="[data.style, data.staticStyle]"
		v-bind="data.attrs"
		v-on="listeners"
	>
		<slot />
	</div>
</template>

<script>
import BaseCol from '@f/components/BaseCol'
export default {
	extends: BaseCol,
	name: 'BaseCol',
	globalComponent: true
}
</script>

<style lang="scss" scoped>
@mixin make-col-auto() {
	flex: 0 0 auto;
	width: auto;
}

@mixin make-col($size, $columns: $grid-columns) {
	flex: 0 0 auto;
	width: percentage(math.div($size, $columns));
}

@mixin make-col-offset($size, $columns: $grid-columns) {
	$num: math.div($size, $columns);
	margin-left: if($num==0, 0, percentage($num));
}

@mixin make-grid-columns($columns: $grid-columns, $gutter: $grid-gutter-width, $breakpoints: $grid-breakpoints) {
	%grid-column {
		position: relative;
		width: 100%;
		padding-right: math.div($gutter, 2);
		padding-left: math.div($gutter, 2);
	}

	@each $breakpoint in map-keys($breakpoints) {
		$infix: breakpoint-infix($breakpoint, $breakpoints);

		@if $columns>0 {
			@for $i from 1 through $columns {
				.col#{$infix}-#{$i} {
					@extend %grid-column;
				}
			}
		}

		@include media-breakpoint-up($breakpoint, $breakpoints) {
			.col#{$infix} {
				flex: 1 0 0%;
			}

			.col#{$infix}-auto {
				@include make-col-auto();
			}

			@if $columns>0 {
				@for $i from 1 through $columns {
					.col#{$infix}-#{$i} {
						@include make-col($i, $columns);
					}
				}

				@for $i from 0 through ($columns - 1) {
					@if not ($infix==""and $i==0) {
						.offset#{$infix}-#{$i} {
							@include make-col-offset($i, $columns);
						}
					}
				}
			}
		}
	}
}

@include make-grid-columns();

</style>
