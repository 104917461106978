import { getPath } from 'utils/getPath'

const NavigationMixin = {
	props: {
		url: String,
		to: String,
		params: {
			type: Object,
			default: () => ({})
		},
		query: {
			type: Object,
			default: () => ({})
		},
		target: String
	},
	computed: {
		href () {
			return getPath({
				app: this.$app,
				to: this.to,
				params: this.params,
				query: this.query
			})
		},
		tag () {
			return this.to || this.url ? 'a' : 'button'
		},
		formattedUrl () {
			if (this.url !== undefined) return this.url.replace(/\s/g, '')
			return this.url
		},
		path () {
			return this.to ? this.href : this.formattedUrl
		},
		targetValue () {
			return this.target || (this.url ? '_blank' : '_self')
		}
	},
	methods: {
		handleClick () {
			this.$emit('click')
			if (this.to && this.$route.fullPath === this.href) return false
			if (this.to && this.targetValue !== '_blank') this.$router.push(this.path)
			else if (this.path) window.open(this.path, this.targetValue)
		}
	}
}

export {
	NavigationMixin
}
