<template>
	<div class="error-wrapper">
		<div
			ref="error"
			class="field-error"
			:style="{'maxHeight': maxHeightValue}"
		>{{error ? error : ''}}</div>
	</div>
</template>

<script>
import { wait } from 'utils/mask/index'
export default {
	props: {
		error: [String, Boolean]
	},
	data () {
		return {
			maxHeight: 0
		}
	},
	computed: {
		maxHeightValue () {
			return this.maxHeight + 'px'
		}
	},
	mounted () {
		this.displayError()
	},
	methods: {
		async displayError () {
			if (!this.$refs.error) return 0
			if (!this.error) {
				this.maxHeight = 0
				await wait(500)
				return 0
			}
			const width = this.$refs.error.clientWidth
			this.maxHeight = (Math.floor(this.error.length * 10 / width) + 1) * 20
		}
	},
	watch: {
		error: 'displayError'
	}
}
</script>
<style lang="scss" scoped>
.error-wrapper {
	min-height: 20px;
	text-align: right;
	@include transition;
	&:deep(.field-error) {
		flex-basis: 100%;
		color: $input-error-color;
		font-size: 12px;
		line-height: 16px;
		overflow: hidden;
		@include transition;
	}
	@include media-breakpoint-up(lg) {
		&:deep(.field-error) {
			font-size: 12px;
		}
	}
}
</style>
