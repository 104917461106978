<template>
	<component
		:is="input.component"
		v-bind="$props"
		class="input-template"
		:class="{wider: wider, primary: primary}"
	/>
</template>

<script>
export default {
	props: {
		input: Object,
		center: Boolean,
		rows: String,
		wider: Boolean,
		primary: Boolean,
		autocomplete: String
	}
}
</script>
<style lang="scss" scoped>
.input-template {
	&.wider {
		width: 100%;
	}
	&.primary :deep(.fs-label) {
		color: $primary;
	}
}
</style>
