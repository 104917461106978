<template>
	<div class="helper-wrapper">
		<div class="wrapper" :class="{active: isOpen}">
			<topbar-close @close="close">{{langs.title}}</topbar-close>
			<div class="items-wrapper">
				<cart-item v-for="item in cart.items" :loading="cart.loading" :key="item.product.id" :item="item" />
				<base-font size="sm" class="empty-cart" v-if="!cart.items.length">{{langs.empty_cart}}</base-font>
			</div>
			<price-wrapper :cart="cart" allowRemoveItems :toPay="cart.amount"/>
			<div class="inner-wrapper">
				<div class="buttons-wrapper">
					<base-button class="go-cart-btn" @click="close()" to="cart-page" type="secondary">
						<span>{{langs.button_text}}</span>
					</base-button>
					<base-button class="go-cart-btn" @click="close()" type="primary">
						<span>{{langs.continue_shopping}}</span>
					</base-button>
				</div>
				<div class="bottom-wrapper" v-if="cart.freeDeliveryAvailable">
					<template v-if="cart.leftToFreeDelivery">
						<base-font color="primary">
							{{ langs.free_shipping_info }}
							<skeleton-loader class="cart-skeleton" :isVisible="cart.loading">
								<base-price class="price-to-free-ship" :price="cart.leftToFreeDelivery"/>
							</skeleton-loader>
						</base-font>
					</template>
					<template v-else>
						<base-font class="free-ship-available" color="primary" size="sm" weight="bold" marginReset>{{ langs.free_available_shipping_info }}</base-font>
					</template>
				</div>
			</div>

		</div>
		<div class="overlay" @click="close"></div>
	</div>
</template>

<script>
import CartItem from '../CartItem'
import PriceWrapper from '../PriceWrapper'
import TopbarClose from '~/website/front/components/molecules/TopbarClose/'
import SkeletonLoader from '~/website/front/components/molecules/loaders/SkeletonLoader/SkeletonLoader'

export default {
	components: {
		CartItem,
		PriceWrapper,
		SkeletonLoader,
		TopbarClose
	},
	computed: {
		isOpen () {
			return this.$app.cartModule.isOpen
		},
		cart () {
			return this.$app.cart
		},
		langs () {
			return this.$app.translator.get('cart')
		}
	},
	methods: {
		close () {
			this.$app.cartModule.close()
		}

	},
	async prefetch () {
		await this.$app.translator.prefetch('cart')
	}
}
</script>
<style lang="scss" scoped>
.buttons-wrapper {
	display: flex;
	flex-direction: column;
}

.wrapper {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	position: fixed;
	top: 0;
	right: 0;
	width: 100%;
	height: 100vh;
	z-index: $level2;
	background-color: $white;
	box-shadow: $secondary-shadow;
	transform: translateX(110%);
	overflow-y: scroll;
	@include transition;
	&.active {
		transform: translateX(0);
		& + .overlay {
			pointer-events: inherit;
			opacity: 1;
		}
	}

	@include media-breakpoint-up(md) {
		width: 410px;
		z-index: $level3;
	}
	@include media-breakpoint-landscape() {
		width: 60vw;
	}
}
.items-wrapper {
	padding: 0 3rem;
	overflow: auto;
	overflow-x: hidden;

	&::-webkit-scrollbar {
		width: 5px;
	}

	&::-webkit-scrollbar-track {
		background: $gray-500;
	}

	&::-webkit-scrollbar-thumb {
		background: $gray-800;
	}
}
.inner-wrapper {
	padding-top: 1rem;
	background-color: $gray-200;

	& :deep(.btn)  {
		margin: 2rem auto;
	}
	& :deep(.bottom-wrapper)  {
		margin: 1rem;
	}
	.cart-skeleton {
		display: inline-block;
	}
	@include media-breakpoint-landscape() {
		padding-top: 1rem;
	}
	@include media-breakpoint-up(lg) {
		padding: 0 3rem 2rem;
	}
	@include media-breakpoint-up(xxl) {
		padding: 0 3rem 3rem;
	}
}

.overlay {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: $level1;
	pointer-events: none;
	opacity: 0;
	background-color: rgba($gray-100, .3);
	@include transition;
}
.price-to-free-ship {
	color: $green-400;
	font-weight: bold;
}
:deep(.free-ship-available) {
	color: $green-400!important;
}

</style>
