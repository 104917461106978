<template>
	<div class="progress-bar-wrapper">
		<base-container>
			<div class="progress-bar">
				<template v-for="item in barItems">
					<span
						v-if="getItemIndex(item) < currentItemIndex && !isOrderCompleted"
						class="progress-item completed"
						:key="item.name"
						:class="{active: item.to === $app.page.route.meta.alias}"
						@click="goBack(item)"
					>
						{{item.name}}
					</span>
					<span v-else
						class="progress-item"
						:key="item.name"
						:class="{active: item.to === $app.page.route.meta.alias}"
					>
						{{item.name}}
					</span>
				</template>
			</div>
		</base-container>
	</div>
</template>
<script>
import { getPath } from 'utils/getPath'

export default {
	computed: {
		barItems () {
			return this.$app.layout.value['checkout-progress'].checkout_progress
		},
		currentItemIndex () {
			const currentItemAlias = this.$app.page.route.meta.alias
			const barItem = this.barItems.find(item => item.to === currentItemAlias)
			const itemIndex = this.barItems.indexOf(barItem)
			return itemIndex
		},
		isOrderCompleted () {
			return !this.$app.page.draftOrder
		}
	},
	methods: {
		getItemIndex (item) {
			const barItem = this.barItems.find(barItem => barItem.to === item.to)
			const itemIndex = this.barItems.indexOf(barItem)
			return itemIndex
		},
		goBack (item) {
			const destinationRouteIndex = this.getItemIndex(item)

			if (destinationRouteIndex === 0) {
				this.$app.vue.$router.push(getPath({
					app: this.$app,
					to: this.barItems[destinationRouteIndex].to
				}))
			} else if (this.$app.page.draftOrder) {
				this.$app.vue.$router.go(destinationRouteIndex - this.currentItemIndex)
			}
		}
	}
}
</script>
<style lang="scss" scoped>
	.progress-bar-wrapper {
		display: none;
		position: absolute;
		bottom: -3.3rem;
		left: 0;
		width: 100%;
		z-index: $level1;

		@include media-breakpoint-up(md) {
			display: block;
		}
		.progress-bar {
			display: flex;
			justify-content: space-between;
			align-items: flex-end;
			cursor: default;

			.progress-item{
				position: relative;
				padding-top: 1.5rem;
				font-weight: 500;
				color: $gray-700;
				@include transition;

				&.completed {
					transition: color 1s;
					&:hover {
						cursor: pointer;
						color: $gold-600;
						font-weight: 700;
					}
				}

				&.active {
					color: $gold-600;
					font-weight: 700;
				}

				&::before, &::after {
					content: '';
					position: absolute;
					height: 3px;
					top: -1px;
					right: 50%;
					@include transition;

				}
				&.active::before {
					background: $gold-600;
					width: 100vw;
					border-right: 5px solid rgba($secondary, .5);

				}
			}
		}
	}
</style>
