<template>
	<form class="form" v-if="form">
		<div class="form-inner-wrapper">
			<input-template :input="form.inputs.code" />
		</div>
		<div class="form-btn-wrapper">
			<product-button @click="submit" class="form-button">
				{{ langs.discount_code_apply }}
			</product-button>
		</div>
		<p v-if="status !== 'SUCCESS' && status.length" class="form-error-handler">
			{{statusTranslation.error}}
		</p>
	</form>
</template>

<script>
import { Form } from '@f/core/forms/Form'
import { InputTemplate, InputText } from '@f/core/forms/inputs'
import { required } from 'utils/inputs/validation-front'
import ProductButton from '../ProductButton'

export default {
	data () {
		return {
			code: 'tset',
			status: '',
			form: false
		}
	},
	components: {
		InputTemplate,
		ProductButton
	},
	computed: {
		statusTranslation () {
			return this.$app.translator.get('forms.discountCode.status')
		},
		cart () {
			return this.$app.cart
		},
		langs () {
			return this.$app.translator.get('cart')
		}
	},
	async prefetch () {
		await this.$app.translator.prefetch('cart')
		await this.$app.translator.prefetch('errors')
		await this.$app.translator.prefetch('forms.discountCode')
		this.form = new Form()
		this.form.addInput(InputText.create({
			translations: () => this.$app.translator.get('forms.discountCode.inputCode'),
			attribute: 'code',
			name: 'code',
			validation: [required('Nie wprowadzono kodu rabatowego')]
		}))
	},
	methods: {
		async submit () {
			if (!await this.form.validate()) return false
			const { code } = this.form.getValues()
			const data = await this.cart.applyDiscountCode(code)
			if (data.error) this.status = data.alias
			else this.status = 'SUCCESS'
		}
	}
}
</script>
<style lang="scss" scoped>
.form {
	&-inner-wrapper {
		& :deep(input) {
			border: 1px solid $primary;
		}
		& :deep(label) {
			color: $primary;
		}
	}
	&-btn-wrapper {
		display: flex;
		align-items: flex-end;
		justify-content: space-between;
	}

	& :deep(.form-button) {
		padding: 1rem 1rem;
		margin-top: 5px;
		flex-grow: 0;
	}
	.form-error-handler {
		margin-top: 1rem;
		color: $danger-500;
	}
}
</style>
