<script>
const colType = {
	type: String,
	validator: (value) => value <= 12 || value < 1
}
export default {
	functional: true,
	props: {
		col: colType,
		sm: colType,
		md: colType,
		lg: colType,
		xl: colType,
		xxl: colType,
		offsetSm: colType,
		offsetMd: colType,
		offsetLg: colType,
		offsetXl: colType,
		offsetXxl: colType
	},
	classes (props, data) {
		const sizes = Object.keys(props).filter(key => key)
		const colsClasses = {}
		sizes.forEach((size) => {
			let objName
			if (size === 'col') objName = `${size}-${props[size]}`
			else if (size.includes('offset')) {
				const offsetSize = size.split('offset')[1].toLowerCase()
				objName = `offset-${offsetSize}-${props[size]}`
			} else objName = `col-${size}-${props[size]}`
			colsClasses[objName] = !!props[size]
		})

		const dynamicClasses = data.class || {}
		const staticClasses = data.staticClass ? data.staticClass.split(' ').reduce((final, item) => {
			final[item] = true
			return final
		}, {}) : {}

		return {
			...colsClasses,
			...staticClasses,
			...dynamicClasses
		}
	}
}
</script>
