import { Modal } from '@f/core/Modal'
import RelatedProductsModalComponent from './RelatedProductsModal.vue'

class RelatedProductsModal extends Modal {
	component = RelatedProductsModalComponent
	title = 'Produkty które mogą Cię zainteresować'

	constructor (product) {
		super()
		this.product = product
	}
}

export { RelatedProductsModal }
