import { rextServiceExtend } from './core/services/RextService'
import { CartModule } from './core/components/CartModule'
import { Cart } from './core/entities/Cart'
import { defaultLayoutExtend } from './core/layouts/DefaultLayout'
import { Product } from '~/shop/front/core/entities/Product/Product'
import { Category } from '~/shop/front/core/entities/Category/Category'
import { Review } from '~/shop/front/core/entities/Review/Review'
import { Order } from './core/entities/Order/Order'
import { DraftOrder } from '~/shop/front/core/entities/DraftOrder/DraftOrder'
import { productAddedToCart } from './core/handlers/productAddedToCart'
import { InpostService } from '~/shop/front/core/services/InpostService'
const ProductPage = async () => (await import('./core/pages/Product')).ProductPage
const CheckoutPage = async () => (await import('./core/pages/Checkout/Checkout')).CheckoutPage
const SummaryPage = async () => (await import('./core/pages/Summary/Summary')).SummaryPage
const ReturnPage = async () => (await import('./core/pages/ReturnPage')).ReturnPage
const CartPage = async () => (await import('./core/pages/CartPage')).CartPage
const UserProfilePage = async () => (await import('./core/pages/UserProfile')).UserProfilePage
const ProductList = () => import('~/shop/front/sections/ProductList/')
const ProductParagraphCard = () => import('./sections/ProductParagraphCard')
const ProductAdvantages = () => import('./sections/ProductAdvantages')
const ProductDescriptionList = () => import('./sections/ProductDescriptionList')
const ProductDescription = () => import('./sections/ProductDescription')
const ProductIngredients = () => import('./sections/ProductIngredients')
const ProductUsageInfo = () => import('./sections/ProductUsageInfo')

class ShopModule {
	readyToAddServices (app) {
		app.addService(InpostService)
	}

	readyToExtendServices (app) {
		app.extendService('rext', rextServiceExtend)
	}

	readyToAddPages (app) {
		app.addPage('product', ProductPage)
		app.addPage('cart-page', CartPage)
		app.addPage('user-profile', UserProfilePage)
		app.addPage('checkout', CheckoutPage)
		app.addPage('summary', SummaryPage)
		app.addPage('return', ReturnPage)
	}

	readyToAddEntities (app) {
		app.addEntity('product', Product)
		app.addEntity('review', Review)
		app.addEntity('category', Category)
		app.addEntity('order', Order)
		app.addEntity('draft-order', DraftOrder)
	}

	readyToExtendLayouts (app) {
		app.extendLayout('default', defaultLayoutExtend)
	}

	async beforeInitReactive (app) {
		app.cartModule = new CartModule()
		app.addSection('product_paragraph_card', ProductParagraphCard)
		app.addSection('product_description_list', ProductDescriptionList)
		app.addSection('product_list', ProductList)
		app.addSection('product_advantages', ProductAdvantages)
		app.addSection('product_description', ProductDescription)
		app.addSection('product_ingredients', ProductIngredients)
		app.addSection('product_usage_info', ProductUsageInfo)
		app.cart = new Cart(app)

		app.on('cart:product:added', productAddedToCart(app))
	}
}

export { ShopModule }
