import { Modal } from '@f/core/Modal'
import InpostModalTemplate from './InpostModal.vue'

class InpostModal extends Modal {
	static async createAndOpen (choosedPointId) {
		const modal = super.createAndOpen(...arguments)
		await modal.app.translator.prefetch('forms.inpost')
		modal.title = modal.app.translator.get('forms.inpost.title')

		await modal.app.vue.$nextTick()
		await modal.initializeMap(choosedPointId)
		return modal
	}

	static async openAndReturnPoint (pointName = null) {
		const modal = await this.createAndOpen(pointName)
		const point = await new Promise(resolve => {
			modal.returnPoint = resolve
		})
		return point
	}

	component = InpostModalTemplate
	title = ''
	inpostInstance = null
	mapInstance = null

	async initializeMap (choosedPointId, htmlElement = 'inpost-map') {
		const service = await this.app.getService('inpost')
		this.inpostInstance = await service.getInpost()

		this.inpostInstance.init({
			defaultLocale: 'pl',
			mapType: 'osm',
			searchType: 'osm',
			points: {
				types: ['parcel_locker']
			},
			map: {
				initialTypes: ['parcel_locker']
			}
		})
		this.mapInstance = this.inpostInstance.mapWidget(htmlElement, this.choosePoint.bind(this))
		this.mapInstance.searchLockerPoint(choosedPointId)
	}

	choosePoint (point) {
		this.close(point)
	}

	close (point = false) {
		this.returnPoint(point)
		super.close()
	}
}

export { InpostModal }
