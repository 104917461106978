import { AuthViews } from '~/authorization/front/core/pages/Auth/Auth'

class Authorization {
	token
	client

	constructor (app) {
		this.app = app
	}

	get isAuthorizated () {
		if (!this.token) return false
		return true
	}

	async init () {
		await this.restoreToken()
	}

	async signIn (data) {
		const service = this.app.getService('rext')
		const response = await service.createToken(data)

		if (response.error) return response

		this.setToken(response)

		const userResponse = await this.fetchUser()

		if (userResponse.error) return { error: true }

		await this.app._emit('user:authorizated')
		return { error: false }
	}

	async signUp (data) {
		const service = this.app.getService('rext')
		const response = await service.signUp(data)

		if (response.error) return response
		const { email, password } = data.user
		await this.signIn({ email, password })

		this.app._emit('sign_up')

		return { error: false }
	}

	async fetchUser () {
		const service = this.app.getService('rext')
		const response = await service.getMe()

		if (response.error) {
			this.removeTokenCookie()
			return { error: response.error }
		}

		this.client = response
		this.app._emit('user:fetched')

		return {
			error: false
		}
	}

	removeTokenCookie () {
		this.app.vue.cookies.remove('rext-token-client')
		this.token = false
	}

	setToken (token) {
		this.token = token
		this.app.vue.cookies.set('rext-token-client', JSON.stringify(this.token), { expires: 30 })
	}

	async restoreToken () {
		if (process.client && !window.__CONFIG__.authorizated) return this.removeTokenCookie()
		let token = this.app.vue.cookies.get('rext-token-client')
		if (!token) return false
		token = JSON.parse(token)
		this.token = token
		await this.fetchUser()
	}

	async signOut () {
		const service = this.app.getService('rext')
		await service.deleteToken(this.token.id)
		this.removeTokenCookie()
		this.app._emit('user:logout')
		if (process.client) {
			this.app.vue.redirection.to('auth', {
				params: {
					view: AuthViews.signIn
				}
			}) && window.location.reload()
		}
	}
}

export { Authorization }
