import { InputEmail, InputPassword, InputText, InputCheckbox } from '@f/core/forms/inputs'
import { email } from 'utils/inputs/validation-front'
import { Form } from '@f/core/forms/Form'
import { required } from 'utils/inputs/validation'
import { getPasswordRules } from 'utils/inputs/passwordRules'
import { Rule } from '@f/core/forms/inputs/InputPassword'

class SignUp {
	status = ''
	processing = false
	constructor (app, strategy) {
		this.app = app
		this.strategy = strategy
	}

	get translations () {
		return this.app.translator.get('forms.auth.signUp')
	}

	async createForm () {
		await this.app.translator.prefetch('errors')
		await this.app.translator.prefetch('forms.auth.signUp')

		this.form = new Form()
		this.form.addInput(InputText.create({
			translations: () => this.app.translator.get('forms.auth.signUp.firstName'),
			attribute: 'firstName',
			name: 'firstName',
			validation: [required()]
		}))
		this.form.addInput(InputText.create({
			translations: () => this.app.translator.get('forms.auth.signUp.lastName'),
			attribute: 'lastName',
			name: 'lastName',
			validation: [required()]
		}))
		this.form.addInput(InputEmail.create({
			translations: () => this.app.translator.get('forms.auth.signUp.inputEmail'),
			attribute: 'email',
			name: 'email',
			validation: [email()]
		}))
		this.form.addInput(InputPassword.create({
			translations: () => this.app.translator.get('forms.auth.signUp.inputPassword'),
			attribute: 'password',
			name: 'password',
			validation: [],
			rules: getPasswordRules(Rule, this.app)
		}))
		this.form.addInput(InputCheckbox.create({
			translations: () => this.app.translator.get('forms.auth.signUp.newsletter'),
			attribute: 'newsletter',
			name: 'newsletter'
		}))
		this.form.addInput(InputCheckbox.create({
			translations: () => this.app.translator.get('forms.auth.signUp.regulations'),
			attribute: 'regulations',
			name: 'regulations',
			validation: [required()]
		}))
	}

	async signUp () {
		if (!await this.form.validate()) return false
		this.processing = true

		const { firstName, lastName, email, password, newsletter } = this.form.getValues()

		const data = {
			firstName,
			lastName,
			newsletter,
			user: {
				email,
				password
			}
		}
		const { error } = await this.app.authorization.signUp(data)

		if (error) return this.strategy.error(error)

		await this.strategy.success()

		const service = await this.app.getService('rext')
		await service.klaviyoCreateProfile({
			email: data.user.email,
			first_name: data.firstName,
			last_name: data.lastName
		})

		if (data.newsletter) {
			await service.klaviyoCreateNewsletterEvent({ email: data.user.email })
		}
	}

	markAsError (error) {
		const message = {
			USER_EMAIL_IS_NOT_UNIQUE: this.translations.signUpStatus.ERROR
		}[error] || this.translations.signUpStatus.SERVER_ERROR

		this.status = {
			message,
			code: 'error'
		}
		this.processing = false
	}
}

export { SignUp }
