<template>
	<div class="search-wrapper">
		<div class="icon-wrapper" @click="toggleSearchVisibility">
			<base-svg class="search-icon" name="search" />
		</div>
		<div class="product-search" :class="{ 'show-search': scrollDirection === 'down' }" v-if="isInputActive">
			<input class="search-input" type="text" placeholder="Szukaj produktu" v-model="search" @input="inputHandler" @keydown.esc="toggleSearchVisibility">
			<ul class="searched-products" v-show="products.length">
				<li class="product" v-for="product in products" :key="product.alias" @click="toggleSearchVisibility">
					<base-link class="link" type="primary" to="product" :params="{ alias: product.alias }">{{product.name}}</base-link>
				</li>
			</ul>
		</div>
	</div>
</template>

<script>
import { getPath } from 'utils/getPath'

export default {
	props: {
		scrollDirection: String
	},
	data () {
		return {
			search: '',
			products: [],
			isInputActive: false
		}
	},
	methods: {
		goToProductPage () {
			this.$router.push({
				path: getPath({
					app: this.$app,
					to: 'product-list'
				}),
				query: {
					search: this.search
				}
			})
		},
		inputHandler () {
			if (this.timeout) clearTimeout(this.timeout)
			this.timeout = setTimeout(this.searchProducts.bind(this), 1000)
		},
		async searchProducts () {
			this.products = await this.$app.getService('rext').searchProducts(this.search)
		},
		toggleSearchVisibility () {
			this.isInputActive = !this.isInputActive
			this.search = ''
			this.products = []
		}
	}
}
</script>

<style scoped lang="scss">
.search-wrapper {
	align-self: center;
	position: relative;
	justify-content: center;
	align-items: center;

	@include media-breakpoint-up(xl) {
		height: 100%;
		flex-grow: 1;
		background-color: $gray-500;
		cursor: pointer;
		@include transition;
		&:hover {
			background-color: $gray-300;
		}
		&:last-child {
			margin-right: 0;
		}
	}
	@include media-breakpoint-up(xxl) {
		min-width: 100px;
	}
	.cart-text-desktop {
		display: none;
		@include media-breakpoint-up(xl) {
			display: block;
		}
	}

	.icon-wrapper {
		display: flex;
		align-items: center;
		justify-content: center;

		@include media-breakpoint-up(xl) {
			height: 100%;
		}
	}

	.search {
		position: fixed;
	}

	.search-icon {
		width: 35px;
		height: 35px;
		cursor: pointer;
	}
}

.product-search {
	position: fixed;
	display: flex;
	flex-direction: column;
	background: #F6F6F6;
	box-shadow: 0 3px 12px rgb(0 0 0 / 36%);
	width: 100vw;
	left: 0;
	top: $menuHeight;
	transform: translateY(calc(-140% - $menuHeight));
	transition: 0.3s ease-in;

	@include media-breakpoint-up(xl) {
		min-width: 300px;
		width: initial;
		left: initial;
		top: initial;
		transform: translateX(-25%) translateY(10px);
	}

	&.show-search {
		transform: translateY(0);
	}

	.search-input {
		border: none;
		border-bottom: 1px solid #b79f5b;
		background: transparent;
		font-size: 1.6rem;
		padding: 15px 0 15px 15px;
		width: 100%;
		height: 45px;
		padding-right: 30px;
		line-height: 1;
		outline: none;
	}

	.searched-products {
		list-style-type: none;
		padding: 0;
		margin: 0;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;

		.product {
			height: 40px;
			width: 100%;
			display: flex;
			align-items: center;
			justify-content: center;
			cursor: pointer;

			&:hover {
				transform: scale(1.05);
			}

			&:nth-child(odd) {
				background: white;
			}
			&:nth-child(even) {
				background: #F6F6F6;
			}

			.link {
				width: 100%;
				height: 100%;
				text-align: center;
				transform: translateY(25%);
			}
		}
	}
}
</style>
<style>
.safari .navbar-wrapper .logo-wrapper .search-wrapper {
	right: 100px;
}
</style>
