class Product {
	static async fetch ({ take, skip, where, order }) {
		const service = this.app.getService('rext')
		const data = await service.getProducts({ take, skip, where, order })

		if (data.error) return []

		const Product = this.app.getEntity('product')

		return {
			products: data.products.map(item => Product.create(item)),
			numberOfAllProducts: data.numberOfAllProducts
		}
	}

	static async fetchOne (alias) {
		const service = this.app.getService('rext')
		const data = await service.getProduct(alias)

		if (data.error) return null

		const Product = this.app.getEntity('product')
		return Product.create(data)
	}

	static create (data = {}) {
		const Product = this.app.getEntity('product')
		const instance = new Product()
		const Review = this.app.getEntity('review')

		if (data.recommendedProducts && data.recommendedProducts.length > 0) {
			instance.recommendedProducts = data.recommendedProducts.map(row => Product.create(row))
		}
		delete data.recommendedProducts
		if (data.relatedProducts && data.relatedProducts.length > 0) {
			instance.relatedProducts = data.relatedProducts.map(row => Product.create(row))
		}
		delete data.relatedProducts
		if (data.list) data.reviews.list = data.reviews.list.map(data => Review.create(data))

		Object.entries(data).forEach(([property, value]) => {
			if (property in instance) instance[property] = value
		})

		return instance
	}

	id
	page
	name
	alias
	category
	code
	weight
	capacity
	price
	priceBeforeDiscount
	discountEndDate
	thumbnail
	description
	shortDescription
	tags
	reviews = {}
	avgRate
	numberOfReviews
	recommendedProducts = false
	relatedProducts = false

	get app () {
		return this.constructor.app
	}

	get isDiscount () {
		return this.price < this.priceBeforeDiscount
	}

	async addToCart (quantity = 1) {
		await this.app.cart.addProduct(this, quantity)

		const service = await this.app.getService('rext')
		if (this.app.authorization.isAuthorizated) {
			console.log('Product', this.name, this.category.name, this.price)
			await service.klaviyoCreateProductAddEvent(
				{
					email: this.app.authorization.client.user.email,
					productName: this.name,
					productCategory: this.category.name,
					productValue: this.price
				}
			)
		}
	}

	async fetchReviews ({ skip, take }) {
		const service = this.app.getService('rext')
		const data = await service.getProductReviews({ id: this.id, skip, take })
		if (data.error) return {}

		const Review = this.app.getEntity('review')

		return {
			count: data.count,
			list: data.reviews.map(item => Review.create(item))
		}
	}

	async loadReviews (take) {
		this.reviews = await this.fetchReviews({ take })
	}

	async loadMoreReviews (take) {
		const { list } = await this.fetchReviews({
			skip: this.reviews.list.length,
			take
		})
		this.reviews.list = this.reviews.list.concat(...list)
	}
}

export { Product }
