<template functional>
	<component
		v-if="props.html"
		:is="$options.tag(props)"
		:ref="data.ref"
		:class="$options.classes(props, data)"
		:style="[data.style, data.staticStyle]"
		v-bind="data.attrs"
		v-on="listeners"
		v-html="props.html"
	/>
	<component
		v-else
		:is="$options.tag(props)"
		:ref="data.ref"
		:class="$options.classes(props, data)"
		:style="[data.style, data.staticStyle]"
		v-bind="data.attrs"
		v-on="listeners"
	>
		<slot />
	</component>
</template>

<script>
import BaseFont from '@f/components/BaseFont'
export const tags = ['', 'div', 'a', 'p', 'span', 'label', 'h1', 'h2', 'h3', 'h4', 'h5', 'h6']
export const colors = ['primary', 'black', 'white', 'gold']
export const fontFamily = ['primary', 'secondary']
export const weights = ['', 'bold', 'medium', 'regular']
export const sizes = ['', 'price', 'date', 'menu', 'label', 'xs', 'sm', 'md', 'lg', 'cta', 'hero-medium', 'hero']
export const variants = {
	menu: {
		color: 'primary',
		tag: 'span',
		size: 'menu',
		weight: 'medium',
		family: 'primary'
	},
	small: {
		color: 'primary',
		tag: 'span',
		size: 'date',
		family: 'primary'
	},
	price: {
		color: 'primary',
		tag: 'span',
		size: 'price',
		family: 'secondary'
	},
	paragraph: {
		color: 'primary',
		tag: 'p',
		size: 'xs',
		family: 'primary'
	},
	label: {
		color: 'primary',
		tag: 'label',
		size: 'label',
		weight: 'bold',
		family: 'primary'
	},
	'small-header': {
		color: 'black',
		size: 'sm',
		weight: 'bold',
		family: 'primary',
		tag: 'h3'
	},
	'medium-header': {
		color: 'primary',
		tag: 'h2',
		size: 'md',
		family: 'secondary'
	},
	'section-header': {
		color: 'primary',
		size: 'lg',
		tag: 'h1',
		family: 'secondary'
	},
	cta: {
		color: 'white',
		tag: 'h3',
		size: 'cta',
		family: 'secondary'
	},
	'hero-header-medium': {
		color: 'white',
		tag: 'h3',
		size: 'hero-medium',
		family: 'secondary'
	},
	'hero-header': {
		color: 'white',
		tag: 'h2',
		size: 'hero',
		family: 'secondary'
	}
}

export default {
	extends: BaseFont,
	name: 'BaseFont',
	globalComponent: true
}
</script>

<style lang="scss" scoped>
.fs {
	&-hero {
		font-size: 4.3rem;
		line-height: 4.5rem;
		letter-spacing: 0.03em;
		font-weight: normal;
		@include media-breakpoint-up(md) {
			font-size: 5.5rem;
			line-height: 8.3rem;
		}
		@include media-breakpoint-up(lg) {
			font-size: 7rem;
		}
		@include media-breakpoint-up(xl) {
			font-size: 10rem;
			line-height: 13.3rem;
		}
		&-medium {
			font-size: 2.4rem;
			line-height: 2.5rem;
			letter-spacing: 0.03em;
			font-weight: normal;
			@include media-breakpoint-up(md) {
				font-size: 3.2rem;
				line-height: 3.6rem;
			}
			@include media-breakpoint-up(xl) {
				font-size: 4.2rem;
				line-height: 5.6rem;
			}
		}
	}
	&-cta {
		font-size: 2.6rem;
		line-height: 3.5rem;
		letter-spacing: 0.03em;
		font-weight: 400;
		@include media-breakpoint-up(md) {
			font-size: 5.4rem;
			line-height: 6.6rem;
			margin-bottom: 2rem;
		}
		@include media-breakpoint-up(xl){
			font-size: 6.2rem;
			line-height: 7.6rem;
			margin-bottom: 3rem;
		}
		@include media-breakpoint-up(xxl) {
			font-size: 8rem;
			line-height: 10.6rem;
			margin-bottom: 4rem;
		}
	}
	&-lg {
		position: relative;
		display: inline-block;
		font-weight: normal;
		font-size: 2.6rem;
		line-height: 3.5rem;
		letter-spacing: 0.00em;
		z-index: 2;
		margin: 4rem 0;
		overflow: hidden;

		@include media-breakpoint-up(md) {
			font-size: 3.6rem;
			line-height: 4.2rem;
			margin: 6rem 0;
		}
		@include media-breakpoint-up(lg) {
			font-size: 5rem;
			line-height: 6.7rem;
			margin: 8rem 0;
		}
	}
	&-md {
		font-size: 1.8rem;
		line-height: 3rem;
		letter-spacing: 0.03em;
		font-weight: normal;
		@include media-breakpoint-up(md) {
			font-size: 2.2rem;
			margin: 2rem 0 1.5rem;
		}
		@include media-breakpoint-up(lg) {
			font-size: 2.8rem;
			margin: 4rem 0 3rem;
		}
	}
	&-sm {
		font-size: 1.6rem;
		line-height: 2rem;
		letter-spacing: 0.06em;
		text-transform: uppercase;
		@include media-breakpoint-up(md) {
			font-size: 1.8rem;
			line-height: 2.5rem;
		}
		@include media-breakpoint-up(xl) {
			margin: 2rem 0;
		}
	}
	&-xs {
		font-size: 1.4rem;
		line-height: 2.4rem;
		letter-spacing: 0.03em;
		@include media-breakpoint-up(md) {
			font-size: 1.6rem;
			line-height: 3rem;
		}
	}
	&-label {
		font-size: 1.4rem;
		line-height: 1.9rem;
		letter-spacing: 0.041em;
		@include media-breakpoint-up(md) {
			font-size: 1.6rem;
		}
	}
	&-price {
		font-size: 1.8rem;
		line-height: 3.2rem;
		letter-spacing: 0.0em;
		@include media-breakpoint-up(md) {
			font-size: 2rem;
		}
		@include media-breakpoint-up(xxl) {
			font-size: 2.4rem;
		}
	}
	&-date {
		font-size: 1rem;
		line-height: 1rem;
		letter-spacing: 0.06em;
	}
	&-menu {
		font-size: 1.4rem;
		line-height: 1.9rem;
		letter-spacing: 0.02em;
		text-transform: uppercase;
		@include media-breakpoint-up(xxl) {
			font-size: 1.6rem;
		}
	}
}
.ff {
	&-primary {
		font-family: $primary-font;
	}
	&-secondary {
		font-family: $secondary-font;
	}
}
.fc {
	&-primary {
		color: $primary;
	}
	&-white {
		color: $white
	}
	&-gold {
		color: $gold;
	}
}

.fuc {
	text-transform: uppercase;
}
.fi {
	font-style: italic;
}
.fw {
	&-bold {
		font-weight: bold;
	}
	&-medium {
		font-weight: 500;
	}
	&-regular {
		font-weight: normal;
	}
}
.mr{
	margin-block: 0!important;
}

</style>
