class Order {
	static async findById (id) {
		const rextService = this.app.getService('rext')
		const data = await rextService.getDraftOrder(id)

		const Order = this.app.getEntity('order')
		return new Order(data)
	}

	constructor (data) {
		if (data) this.setData(data)
	}

	setData (data) {
		Object.entries(data).map(([key, value]) => {
			this[key] = value
		})
	}
}

export { Order }
