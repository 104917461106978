<template>
	<div>
		<div :class="{wrapper: blur, 'darker-blur': darker}">
			<slot/>
		</div>
			<div class="inner-wrapper" v-if="blur">
			<slot name="status"/>
		</div>
	</div>
</template>

<script>
export default {
	globalComponent: true,
	props: {
		blur: Boolean,
		darker: Boolean
	}
}
</script>

<style lang="scss" scoped>
.wrapper {
	filter: blur(5px);

	&.darker-blur {
		background: rgba($black, 0.3);
	}
}
.inner-wrapper {
	position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

}
</style>
