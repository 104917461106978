<template>
	<base-container>
		<div class="user-profile-wrapper" v-if="page.ready || ready">
			<base-row>
				<base-col col="12" lg="4" xxl="3" offset-xxl="1">
					<client-navigation/>
				</base-col>
				<base-col col="12" lg="8" xxl="7">
					<transition
						name="fade"
						mode="out-in"
					>
						<component key="user-view" :is="view.component" />
					</transition>
				</base-col>
			</base-row>
		</div>
	</base-container>
</template>
<script>
import ClientNavigation from '~/shop/front/components/ClientNavigation'

export default {
	data () {
		return {
			ready: false
		}
	},
	components: {
		ClientNavigation
	},
	computed: {
		view () {
			return this.$app.page.view
		},
		langs () {
			return this.$app.translator.get('client-profile')
		},
		page () {
			return this.$app.page
		}
	},
	watch: {
		'page.ready' () {
			this.ready = true
		}
	}
}
</script>
<style lang="scss" scoped>
	.user-profile-wrapper {
		padding-block: 4rem;
		@include media-breakpoint-up(lg) {
			padding-block: 8rem;
			min-height: 590px;
		}
		:deep(.btn-white) {
			width: 50%;
			justify-content: center;
			margin: 5rem 0 0 0;
		}
	}
	.fade-enter-active {
	transition: all .3s ease-in;
	}
	.fade-leave-active {
	transition: all .3s ease-out;
	}
	.fade-enter, .fade-leave-to {
		@include media-breakpoint-up(lg) {
			opacity: 0;
			transform: translateX(50px);
		}
	}
	.fade-leave-to {
		position: absolute;
	}
	* {
		overflow: hidden;
	}
</style>
