<template>
	<div class="mobile-related-products-modal">
		<div class="product-data">
			<cms-image
				class="product-image"
				lazy
				:src="product.thumbnail"
				:base="{width: 150,height: 130}"
				:md="{width: 250, height: 180}"
			/>
			<div class="inner-wrapper">
				<base-font class="product-title" variant="small-header" tag="h2">{{ product.name }}</base-font>
				<base-button @click="handleClick" class="desktop-btn" to="cart-page" type="secondary">
					{{translations.go_to_cart}}
				</base-button>
			</div>
		</div>
		<div v-if="modal.product.relatedProducts" class="info-wrapper">
			<base-font variant="small-header">
				{{translations.related_modal_info}}
			</base-font>
		</div>
		<slim-products-list v-if="modal.product.relatedProducts" horizontalSlider :products="modal.product.relatedProducts" />
		<base-button @click="handleClick" class="mobile-btn" to="cart-page" type="secondary">
			{{translations.go_to_cart}}
		</base-button>
	</div>
</template>

<script>
import SlimProductsList from '~/shop/front/components/SlimProductsList/SlimProductsList.vue'

export default {
	props: {
		modal: Object
	},
	components: {
		SlimProductsList
	},
	computed: {
		product () {
			return this.modal.product
		},
		translations () {
			return this.$app.translator.get('modals')
		}
	},
	methods: {
		handleClick () {
			this.modal.close()
		}
	},
	async prefetch () {
		await this.$app.translator.prefetch('modals')
	}
}
</script>
<style lang="scss" scoped>
.product-data {
	display: flex;
	align-items: center;
	border-bottom: 2px solid $gray-700;
	@include media-breakpoint-up(md) {
		border-bottom: none;
	}
	:deep(.product-title) {
		text-align: center;
	}
	.inner-wrapper {
		display: flex;
		height: 140px;
		flex-direction: column;
		justify-content: center;
		@include media-breakpoint-up(md) {
			justify-content: flex-start;
			align-items: center;
		}
	}
}
.info-wrapper {
	margin-top: 2rem;
}
.desktop-btn {
	display: none !important;
	@include media-breakpoint-up(md) {
		display: block !important;
		width: max-content !important;
	}
}
.mobile-btn {
	@include media-breakpoint-up(md) {
		display: none !important;
	}
}
</style>
