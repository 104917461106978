class CartModule {
	isOpen = false

	toggle () {
		if (this.isOpen) this.close()
		else this.open()
	}

	close () {
		this.isOpen = false
	}

	open () {
		window.app.closeModal()
		this.isOpen = true
	}
}

export { CartModule }
