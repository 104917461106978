
class InpostService {
	static alias = 'inpost'
	isScriptLoaded = false

	constructor (app) {
		this.app = app
	}

	registerInpost () {
		if (this.isScriptLoaded) return
		return new Promise(resolve => {
			const script = document.createElement('script')
			document.body.appendChild(script)
			script.onload = resolve
			script.src = 'https://geowidget.easypack24.net/js/sdk-for-javascript.js'
		})
	}

	async getInpost () {
		await this.registerInpost()
		return window.easyPack
	}
}

export { InpostService }
