export function rextServiceExtend (Service) {
	return class RextService extends Service {
		async klaviyoCreateProfile (profileData) {
			const reqBody = {
				email: profileData.email,
				first_name: profileData.first_name,
				last_name: profileData.last_name
			}

			const response = await this.http.post('/klaviyo/profile/', reqBody)

			if (response.status !== 200) return { error: true }

			return response.data
		}

		async klaviyoCreateHomePageEvent (eventData) {
			const reqBody = {
				email: eventData.email
			}

			const response = await this.http.post('/klaviyo/event/home-page', reqBody)

			if (response.status !== 200) return { error: true }

			return response.data
		}

		async klaviyoCreateProductPageEvent (eventData) {
			const reqBody = {
				email: eventData.email,
				productName: eventData.productName,
				productCategory: eventData.productCategory,
				productValue: eventData.productValue /= Math.pow(10, 2)
			}

			const response = await this.http.post('/klaviyo/event/product-page', reqBody)

			if (response.status !== 200) return { error: true }

			return response.data
		}

		async klaviyoCreateProductAddEvent (eventData) {
			const reqBody = {
				email: eventData.email,
				productName: eventData.productName,
				productCategory: eventData.productCategory,
				productValue: eventData.productValue /= Math.pow(10, 2)
			}

			const response = await this.http.post('/klaviyo/event/product-add', reqBody)

			if (response.status !== 200) return { error: true }

			return response.data
		}

		async klaviyoCreateOrderPageEvent (eventData) {
			const reqBody = {
				email: eventData.email
			}

			const response = await this.http.post('/klaviyo/event/order-page', reqBody)

			if (response.status !== 200) return { error: true }

			return response.data
		}

		async klaviyoCreateOrderConfirmEvent (eventData) {
			const reqBody = {
				email: eventData.email
			}

			const response = await this.http.post('/klaviyo/event/confirm-page', reqBody)

			if (response.status !== 200) return { error: true }

			return response.data
		}

		async klaviyoCreatePageNotFoundEvent (eventData) {
			const reqBody = {
				email: eventData.email
			}

			const response = await this.http.post('/klaviyo/event/page-not-found', reqBody)

			if (response.status !== 200) return { error: true }
			return response.data
		}

		async klaviyoCreateNewsletterEvent (eventData) {
			const reqBody = {
				email: eventData.email
			}

			const response = await this.http.post('/klaviyo/event/newsletter', reqBody)
			if (response.status !== 200) return { error: true }
			return response.data
		}
	}
}
