import { rextServiceExtend } from './core/services/RextService'
import CmsText from '~/cms/front/components/CmsText'
import CmsImage from '~/cms/front/components/CmsImage'
import CmsEditorText from '~/cms/front/components/CmsEditorText'
import { GTMService } from './core/services/GTMService'
import { WebsiteHeadManager } from '~/cms/front/core/managers/WebsiteHeadManager'

class CMSModule {
	beforeInitParts (app) {
		if (process.client) app.gtm = new GTMService(app)
		app.websiteHeadManager = new WebsiteHeadManager()
	}

	readyToExtendServices (app) {
		app.extendService('rext', rextServiceExtend)
	}

	readyToAddPages (app) {
	}

	afterRouterCreated (app) {
		app.router.beforeEach(async (to, from, next) => {
			app.language = to.meta.language
			const pageImportFunction = to.meta.alias ? app.getPage(to.meta.alias) || app.getPage('default') : app.getPage('not-found')
			let Page = await pageImportFunction()
			if (!Page.checkRoute(to)) {
				Page = await app.getPage(
					'not-found'
				)()
				to = { params: { alias: '' }, meta: { alias: '' } }
			}
			await app.waitForVue()
			const page = new Page({ app, route: to, from })
			await page.init()
			app.page = page
			await page.afterRedirect()
			next()
		})

		app.changeLanguage = this.changeLanguage.bind(app)
	}

	changeLanguage (language) {
		const routes = this.router.getRoutes()
		const languageRoutes = routes.filter(route => route.meta.language === language)

		if (languageRoutes.length === 0) return false

		const actualRoute = this.router.currentRoute

		const route = languageRoutes.find(route => route.meta.alias === actualRoute.meta.alias) || languageRoutes[0]

		window.location.pathname = route.path
	}
}
function installComponents (Vue) {
	Vue.component('cms-text', CmsText)
	Vue.component('cms-editor-text', CmsEditorText)
	Vue.component('cms-image', CmsImage)
}
export { CMSModule, installComponents }
