import { render, staticRenderFns } from "./SimplifiedHeader.vue?vue&type=template&id=4b18c022&scoped=true&"
import script from "./SimplifiedHeader.vue?vue&type=script&lang=js&"
export * from "./SimplifiedHeader.vue?vue&type=script&lang=js&"
import style0 from "./SimplifiedHeader.vue?vue&type=style&index=0&id=4b18c022&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4b18c022",
  null
  
)

export default component.exports