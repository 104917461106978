import { Input } from './Input'
import InputCheckboxComponent from '~/website/front/components/molecules/fileds/FieldCheckbox'

class InputCheckbox extends Input {
	component = InputCheckboxComponent

	toggle () {
		this._touched = true
		this.value = !this.value
	}

	get formValue () {
		return this.value ? 1 : 0
	}

	clear () {
		this.value = false
	}
}

export { InputCheckbox }
