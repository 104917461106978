
import { rextServiceExtend } from './core/services/RextServices'

class NewsletterModule {
	readyToExtendServices (app) {
		app.extendService('rext', rextServiceExtend)
	}
}

export { NewsletterModule }
