<template>
	<div class="prices-wrapper" :class="{'extended-info': extendedInfo}">
		<div class="price-wrapper products-price" v-if="extendedInfo">
			<base-font marginReset size="sm" tag="span" weight="bold">{{ langs.cart_page.products_price }}</base-font>
			<skeleton-loader :isVisible="cart.loading">
				<base-price :price="cart.amountBeforeDiscount"/>
			</skeleton-loader>
		</div>
		<div class="price-wrapper shipment-cost" v-if="Number.isInteger(additionalCost)">
			<base-font marginReset size="sm" tag="span" weight="bold">{{ langs.cart_page.additional_cost }}</base-font>
			<skeleton-loader :isVisible="cart.loading">
				<base-price :price="additionalCost"/>
			</skeleton-loader>
		</div>
		<div key="discount-available" v-if="cart.discountCode">
			<div class="discount-info">
				<base-font color="gold" tag="span" size="sm"  uppercase weight="bold">Kupon rabatowy</base-font>
				<div class="discount-info-wrapper">
					<base-font color="primary" tag="span" size="xs"  uppercase weight="bold">{{ cart.discountCode.message }}</base-font>
					<div v-if="allowRemoveItems" class="cart-item-icon cart-item-icon-delete" @click.prevent="deleteDiscountCode">
						<base-svg name="trash_product"/>
					</div>
				</div>
			</div>
		</div>
		<div key="discount-unavailable" v-show="!cart.discountCode && allowRemoveItems" class="discount-code">
			<div class="discount-code-header" @click="discountCodeExpand = !discountCodeExpand">
				<base-font weight="bold" uppercase tag="span">{{ langs.discount_code }}</base-font>
				<base-svg :class="{expanded: discountCodeExpand}" class="discount-code-arrow" name="arrow_down"/>
			</div>
			<transition-expand>
				<form-discount-code v-if="discountCodeExpand" />
			</transition-expand>
		</div>
		<div key="saved-money" class="saved-money price-wrapper" v-if="savingAmount !== 0">
			<span>{{ langs.saved_money }}</span>
			<skeleton-loader :isVisible="cart.loading">
				<base-price :price="savingAmount"/>
			</skeleton-loader>
		</div>
		<div key="to-pay" class="to-pay price-wrapper">
			<base-font class="to-pay-title" variant="medium-header" tag="h3" weight="regular">{{ langs.to_pay }}</base-font>
			<skeleton-loader :isVisible="cart.loading">
				<base-price :price="toPay"/>
			</skeleton-loader>
		</div>
	</div>
</template>
<script>
import TransitionExpand from '~/website/front/components/atoms/TransitionExpand'
import FormDiscountCode from '../FormDiscountCode'
import SkeletonLoader from '~/website/front/components/molecules/loaders/SkeletonLoader'

export default {
	data: () => ({
		discountCodeExpand: false
	}),
	props: {
		cart: Object,
		allowRemoveItems: {
			type: Boolean,
			default: false
		},
		extendedInfo: {
			type: Boolean,
			default: false
		},
		additionalCost: [Number, Boolean],
		toPay: Number
	},
	components: {
		TransitionExpand,
		FormDiscountCode,
		SkeletonLoader
	},
	computed: {
		langs () {
			return this.$app.translator.get('cart')
		},
		savingAmount () {
			return (this.cart.amountBeforeDiscount - this.cart.amount) || 0
		}
	},
	async prefetch () {
		await this.$app.translator.prefetch('cart')
	},
	methods: {
		async deleteDiscountCode () {
			await this.cart.removeDiscountCode()
		}
	}
}
</script>
<style lang="scss" scoped>
.discount-info {
	border-top: 1px solid $gray-500;
    padding-top: 1rem;
	margin-top: 10px;
	.discount-info-wrapper {
		display: flex;
		justify-content: space-between;
		align-items: center;
	}
}
.prices-wrapper {
	margin-top: auto;
	display: flex;
	flex-direction: column;
	padding: 0 3rem .5rem;
	@include media-breakpoint-up(xxl) {
		padding: 0 3rem 3rem;
	}
	&.extended-info {
		padding: 0;
	}
	.price-wrapper {
		display: flex;
		justify-content: space-between;
		align-items: baseline;
	}
	.saved-money {
		position: relative;
		font-size: 1.6rem;
		text-transform: uppercase;
		font-weight: bold;
		letter-spacing: 0.06em;
		color: $green-400;
		padding-top: 3rem;
		@include media-breakpoint-up(lg) {
			font-size: 1.8rem;
			padding-top: 1rem;
		}
		@include media-breakpoint-landscape() {
			padding-top: 0;
		}
		@include media-breakpoint-up(xxl) {
			padding-top: 3rem;
		}
		&::before {
			content: '';
			position: absolute;
			width: 100%;
			height: 1px;
			bottom: -15px;
			left: 0;
			background-color: $gray-100;
		}
	}
	&.extended-info .saved-money {
		padding-top: 1rem;
	}
	.products-price, .shipment-cost {
		font-size: 1.6rem;
		text-transform: uppercase;
		font-weight: bold;
		letter-spacing: 0.06em;
		color: $primary;
		margin-bottom: 10px;
		@include media-breakpoint-up(lg) {
			font-size: 1.8rem
		}
	}
	.shipment-cost{
		margin-bottom: auto;
	}
	:deep(.to-pay) {
		margin-top: 2rem;

		.to-pay-title {
			@include media-breakpoint-up(md) {
				font-size: 2.2rem;
			}
			@include media-breakpoint-up(lg) {
				margin-block: 2rem 1rem;
			}
			@include media-breakpoint-up(xl) {
				font-size: 2.8rem;
			}
			@include media-breakpoint-up(xxl) {
				margin-block: 3rem 2rem;
			}
		}
		span {
			font-size: 1.8rem;
			margin-top: 1.5rem;
			font-family: $secondary-font;
			color: $primary;
		}
		@include media-breakpoint-up(md) {
			margin-top: 0;
			span {
				font-size: 2.2rem;
			}
		}
		@include media-breakpoint-up(xl) {
			span {
				font-size: 2.8rem;
			}
		}
	}
}
.discount-code {
	&-header {
		cursor: pointer;
		border-top: 1px solid $gray-500;
		margin-top: 10px;
	}
	&-arrow {
		width: 7px;
		transform: rotate(90deg);
		margin-left: 1rem;
		@include transition;
		&.expanded {
			transform: rotate(270deg);
		}
	}
}
.cart-item-icon {
	width: 16px;
	height: 18px;
	&-info {
		position: relative;
		&::before {
			content: '';
			position: absolute;
			left: 50px;
			top: -15px;
			height: 50px;
			width: 1px;
			background-color: $gray-500;
		}
	}
	@include media-breakpoint-up(xl) {
		cursor: pointer;
		&:hover {
			&.cart-item-icon-delete :deep(path) {
				fill: $primary;
			}
		}
	}
}
.fade-enter-active {
transition: all .3s ease-in;
}
.fade-leave-active {
transition: all .3s ease-out;
}
.fade-enter, .fade-leave-to {
	@include media-breakpoint-up(lg) {
		opacity: 0;
	}
}
.fade-enter {
	position: absolute;
}
</style>
