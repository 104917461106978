<template>
	<div class="navbar-wrapper" :class="{ 'discount-navbar-wrapper': $app.layout.showGlobalDiscount }">
		<base-container>
			<base-row>
				<base-col col="12">
					<div class="navbar-inner-wrapper">
						<menu-logo-wrapper
							:activeMobileNav="activeMobileNav"
							@menuButtonClick="toggleMobileBtnClick"
						/>
						<div
							class="nav-wrapper"
							:class="{active: activeMobileNav}"
						>
							<nav class="nav">
								<ul class="nav-list">
									<base-item
										v-for="item in menuItems"
										:key="item.name"
										:item="item"
									/>
									<base-link :url="`tel:${phoneNumber}`" class="nav-number">
										<base-svg name="phone"/>
										<base-font variant="menu" color="white" tag="span">Zadzwoń</base-font>
									</base-link>
								</ul>
								<product-search v-if="!isMobile" />
								<base-cart/>
								<login-button/>
							</nav>
						</div>
					</div>
				</base-col>
			</base-row>
		</base-container>
	</div>
</template>
<script>
import MenuLogoWrapper from './components/MenuLogoWrapper'
import BaseItem from './components/BaseItem'
import BaseCart from '../../molecules/BaseCart'
import LoginButton from '../../molecules/LoginButton'
import ProductSearch from '~/shop/front/components/ProductSearch'

export default {
	data () {
		return {
			activeMobileNav: false
		}
	},
	components: {
		MenuLogoWrapper,
		BaseItem,
		BaseCart,
		LoginButton,
		ProductSearch
	},
	computed: {
		phoneNumber () {
			return this.$app.settings.general.contact.phoneNumber
		},
		isMobile () {
			return this.$app.utils.mobile
		}
	},
	props: {
		menuItems: {
			type: Array,
			default: () => [],
			required: true
		}
	},
	methods: {
		toggleMobileBtnClick () {
			this.activeMobileNav = !this.activeMobileNav
		},
		closeMobileMenu () {
			this.activeMobileNav = false
		}
	},
	watch: {
		$route () {
			this.closeMobileMenu()
		}
	}
}
</script>
<style lang="scss" scoped>
.navbar-wrapper {
	.navbar-inner-wrapper {
		display: flex;
	}
	@include media-breakpoint-up(xl) {
		border-bottom: 1px solid $gray-500;
		position: fixed;
		width: 100%;
		top: 0;
		left: 0;
		z-index: $level2;
		background: $gray-400;
	}
}
.discount-navbar-wrapper {
	@include media-breakpoint-up(xl) {
		top: 50px;
	}
}
.nav-wrapper {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	position: fixed;
	top: 65px;
	left: 0;
	width: 100%;
	height: calc(100vh - 105px);
	transform: translateY(-115%);
	transition: transform .4s ease-in;
	background-color: $white;
	z-index: 10001;
	@include media-breakpoint-landscape() {
		transform: translateY(-155%)
	}
	@include media-breakpoint-up(xl) {
		background-color: $gray-400;
		z-index: $level1;

		position: static;
		flex-direction: row-reverse;
		width: auto;
		height: auto;
		transform: none;
		margin-left: auto;
		padding: 0;
		box-shadow: none;
	}
	&.active {
		transform: translateY(105px);
		@include media-breakpoint-landscape() {
			transform: translateY(42px);
		}
	}
}
.nav {
	padding-bottom: 6rem;
	overflow-y: scroll;
	@include media-breakpoint-up(lg) {
		padding-bottom: 0;
		overflow-y: auto;
		padding-bottom: 0;
	}
	@include media-breakpoint-up(xl) {
		display: flex;
	}
	&-list {
		margin-top: 0;
		margin-bottom: 0;
		list-style: none;
		padding-left: 0;
		@include media-breakpoint-up(xl) {
			display: flex;
			margin: auto;
		}
	}
	&-number {
		color: #3a3738;
		font-weight: normal;
		text-decoration: none;
		display: flex;
		align-items: center;
		background: $gold-600;
		padding: 2rem 0 2rem 3rem;
		border-bottom: 1px solid #f5f4f4;
		.svg-icon {
			width: 20px;
			height: 20px;
			margin-right: 10px;
			:deep(path) {
				fill: $white;
			}
		}
		@include media-breakpoint-up(xl) {
			display: none;
		}
	}
}
</style>
