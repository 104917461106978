<template>
	<li class="nav-item" :class="{'user-logged': isLogged}" >
		<div class="nav-link item-wrapper" @click="showSignBox">
			<base-svg class="icon" name="user_account"/>
			<cms-text v-if="!this.isLogged" class="item-text" :props="{variant: 'menu', weight: 'medium', color: 'white'}" value="menu.userAccount" layout/>
			<cms-text v-else class="item-text" :props="{variant: 'menu', weight: 'medium', color: 'white'}" value="menu.loggedAccount" layout/>
		</div>
		<sign-box @closeOverlay="close" :class="{active: isActive}" class="sign-box" />
		<div class="overlay" @click="close"></div>
	</li>
</template>
<script>
import SignBox from '~/authorization/front/components/SignBox'
import { getPath } from 'utils/getPath'
import { AuthViews } from '~/authorization/front/core/pages/Auth/Auth'
import { UserProfileViews } from '~/shop/front/core/pages/UserProfile/UserProfile'

export default {
	components: {
		SignBox
	},
	data: () => ({
		isActive: false
	}),
	computed: {
		isLogged () {
			return this.$app.authorization.isAuthorizated
		}
	},
	methods: {
		createPath (to, view) {
			return getPath({
				app: this.$app,
				to,
				params: { view }
			})
		},
		showSignBox () {
			this.isActive = true
			if (this.$app.utils.mobile && this.isLogged) {
				const path = this.createPath('user-profile', UserProfileViews.orders)
				this.$router.push(path)
				return
			}
			if (this.$app.utils.mobile) {
				const path = this.createPath('auth', AuthViews.signIn)
				this.$router.push(path)
			}
		},
		close () {
			this.isActive = false
		}
	}
}
</script>
<style lang="scss" scoped>
.sign-box {
	display: none;
	@include media-breakpoint-up(xl) {
		display: flex;
		position: fixed;
		top: 0;
		right: 0;
		transform: translateX(100%);
		z-index: 5000;
		@include transition;
		&.active {
			transform: translateX(0);
			& + .overlay {
				pointer-events: inherit;
				opacity: 1;
			}
		}
	}
}

.overlay {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 4000;
	pointer-events: none;
	opacity: 0;
	background-color: rgba($gray-100, .3);
	@include transition;
}
.nav {
	&-item {
		cursor: pointer;
		display: block;
		background-color: $green-700;
		@include media-breakpoint-up(xl) {
			min-width: 120px;
			margin-right: 5rem;
			@include transition;
			&:hover {
				background-color: $green-800;
			}
			&:last-child {
				margin-right: 0;
			}
		}
		&.user-logged {
			background: $gold;
			@include media-breakpoint-up(lg) {
				@include transition;
				&:hover {
					background: $gold-600;
				}
			}
		}
	}
	&-link {
		display: flex;
		height: 100%;
		position: relative;
		text-decoration: none;
		padding: 2rem 2.5rem;

		@include media-breakpoint-up(xl) {
			padding: 3rem 2rem;
			flex-direction: column;
			align-items: center;
			justify-content: center;
		}
		@include media-breakpoint-up(xxl) {
			padding: 3rem 2.5rem
		}
		.icon {
			height: 20px;
			width: 20px;
			margin-right: 10px;
			@include media-breakpoint-up(xl) {
				display: block;
				width: 15px;
				height: 17px;
				margin-bottom: 6px;
				margin-right: 0;
			}
		}
	}
}
</style>
