import { getQuery } from 'utils/query'

export function rextServiceExtend (Service) {
	return class RextService extends Service {
		async getProduct (alias) {
			const { data, status } = await this.http.get(
				`/shop/product/${alias}` + getQuery({
					response: 'shop_view',
					relations: ['page'],
					where: {
						page: {
							status: 'active'
						}
					}
				})
			)

			if (status !== 200) return { error: true }
			return data
		}

		async getProducts ({ take, skip, where, order }) {
			const query = getQuery({
				relations: ['page'],
				take,
				skip,
				where: {
					...where,
					page: {
						status: 'active'
					}
				},
				order
			})
			const { data, status } = await this.http.get(
				`/shop/product${query}`
			)

			if (status !== 200) return { error: true }
			return data
		}

		async getCart (id) {
			const { data, status } = await this.http.get(`/cart/${id}`)

			if (status !== 200) return { error: true }
			return data
		}

		async createCart (items) {
			const { data, status } = await this.http.post('/cart', { items })

			if (status !== 201) return { error: true }
			return data
		}

		async updateCart (id, items) {
			const { data, status } = await this.http.patch(`/cart/${id}`, { items })

			if (status !== 200) return { error: true }
			return data
		}

		async addDiscountCodeToCart (code, cartId) {
			const { data, status } = await this.http.post(`/cart/${cartId}/discount-code`, { code })

			if (status !== 200) return { error: true, alias: data.alias }
			return data
		}

		async removeDiscountCodeToCart (cartId) {
			const { data, status } = await this.http.delete(`/cart/${cartId}/discount-code`)

			if (status !== 204) return { error: true, alias: data.alias }
			return data
		}

		async getCategories () {
			const { data, status } = await this.http.get('/product-category')

			if (status !== 200) return { error: true }
			return data
		}

		async getProductReviews ({ id, skip, take }) {
			const { data, status } = await this.http.get(
				`/product/${id}/review` + getQuery({ skip, take })
			)

			if (status !== 200) return { error: true }
			return data
		}

		async createReview ({ productId, author, email, rate, content }) {
			const http = await this.getHttpWithRecaptcha()
			const { status } = await http.post(`/product/${productId}/review`, {
				author,
				email,
				rate,
				content
			})
			if (status !== 201) return { error: true }
			return status
		}

		async getDeliveriesForDraftOrder (id) {
			const { status, data } = await this.http.get(`/draft-order/${id}/delivery`)
			if (status !== 200) return { error: true }
			return data
		}

		async getPaymentMethodsForDraftOrder (id) {
			const { status, data } = await this.http.get(`/draft-order/${id}/payment-method`)
			if (status !== 200) return { error: true }
			return data
		}

		async updateDraftOrder (id, data) {
			const response = await this.http.patch(`/draft-order/${id}`, data)
			if (response.status !== 200) return { error: true }
			return response.data
		}

		async createOrderFromDraftOrder (id) {
			const response = await this.http.post(`/draft-order/${id}/order`)

			if (response.status !== 201) return { error: true }
			return response.data
		}

		async createDraftOrder ({ cartId, clientId }) {
			const { status, data } = await this.http.post(`/cart/${cartId}/draft-order`, { clientId })

			if (status !== 201) return { error: true }

			return data
		}

		async getDraftOrder (id) {
			const { status, data } = await this.http.get(`/draft-order/${id}`)

			if (status !== 200) return { error: true }

			return data
		}

		async getOrders () {
			const { status, data } = await this.http.get('/me/order')

			if (status !== 200) return { error: true }

			return data
		}

		async getOrder (id) {
			const { status, data } = await this.http.get(`/order/${id}?response=full`)

			if (status !== 200) return { error: true }

			return data
		}

		async fetchOTFD (id) {
			const { status, data } = await this.http.get(`/one-time-fetch-data/${id}`)

			if (status !== 200) return { error: true }

			return data
		}

		async searchProducts (text) {
			const { status, data } = await this.http.get(`/product/search?search=${text}`)

			if (status !== 200) return { error: true }

			return data
		}
	}
}
