import cookies from 'js-cookie'
import { CartItem } from '../CartItem'
import { MetaPixelService } from '~/website/front/core/services/MetaPixelService.js'

const CART_COOKIE = 'CART_COOKIE'
const cartActions = Object.freeze({
	REMOVE: 'remove',
	ADD: 'add'
})
class Cart {
	id
	amount
	amountBeforeDiscount
	leftToFreeDelivery
	freeDeliveryAvailable
	items = []
	discountCode = null
	processingItems = []
	loading = false

	constructor (app) {
		this.app = app
		this.service = app.getService('rext')
		this.id = cookies.get(CART_COOKIE)
		this.app.on('user:authorizated', this.authHandler.bind(this))
		this.app.on('user:logout', this.clear.bind(this))
	}

	authHandler () {
		if (this.id) return this.addToUser()

		this.fetchClientCartIfExist()
	}

	async addToUser () {
		if (this.app.authorization.client.cart?.id === this.id) return false

		await this.app.getService('rext').addCartToUser({
			cartId: this.id
		})
	}

	async fetchClientCartIfExist () {
		if (this.app.authorization.client.cart) this.setId(this.app.authorization.client.cart.id)

		if (!this.id) return false

		await this.fetch()
	}

	async restore () {
		this.id = cookies.get(CART_COOKIE)

		if (!this.id) return

		await this.fetch()
	}

	async fetch () {
		this.loading = true
		const data = await this.service.getCart(this.id)

		if (data.error) return this.clear()

		this.setCartData(data)
		this.loading = false

		if (this.processingItems.length) await this.update()
	}

	setCartData (data) {
		this.amount = data.amount
		this.amountBeforeDiscount = data.amountBeforeDiscount
		this.leftToFreeDelivery = data.leftToFreeDelivery
		this.freeDeliveryAvailable = data.freeDeliveryAvailable
		this.discountCode = data.discountCode
		this.setItems(data.items)
	}

	setItems (items) {
		this.items = items.map(item => new CartItem({
			app: this.app,
			cart: this,
			product: item.product,
			quantity: item.quantity,
			amountAfterDiscount: item.amountAfterDiscount,
			amountBeforeDiscount: item.amountBeforeDiscount
		}))
	}

	get quantity () {
		return this.items.reduce((sum, item) => sum + item.quantity, 0)
	}

	async addProduct (product, quantity) {
		this.app._emit('cart:product:added', { product, quantity })
		this.processingItems.push({
			action: cartActions.ADD,
			data: {
				product,
				quantity
			}
		})
		await this.update()
		this.sendMetaPixelData(product, quantity)
	}

	sendMetaPixelData (product, quantity) {
		const facebookPixelData = {
			page_title: document.title,
			event_url: location.href,
			content_type: 'product',
			user_role: this.app.authorization.isAuthorizated ? 'client' : 'guest',
			total_price: this.amount / 100,
			value: product.price / 100,
			currency: 'PLN',
			post_id: product.id,
			content_name: product.name,
			price_before_discount: product.priceBeforeDiscount ? product.priceBeforeDiscount / 100 : 'unknown',
			quantity: quantity,
			content_ids: this.items.map(item => item.product.id),
			contents: this.app.cart.items.map(item => ({
				id: item.product.id,
				quantity: item.quantity,
				name: item.product.name
			})),
			category_name: this.items.map((item) => item.product.category ? item.product.category.name : 'brak')
		}

		MetaPixelService.emitEvent('AddToCart', facebookPixelData)
	}

	async update () {
		if (this.loading) return

		this.loading = true
		const itemsToSend = this.mergeProcessingItems().map(item => item.toObject())
		const data = await this.sendData(itemsToSend)

		if (data.error) throw Error('Failed update cart')

		this.setCartData(data)
		this.loading = false

		if (this.processingItems.length) await this.update()
	}

	mergeProcessingItems () {
		let mergedItems = this.items.slice()

		this.processingItems.forEach(processingItem => {
			const modifyFn = {
				[cartActions.ADD]: this.addProcessingProduct.bind(this),
				[cartActions.REMOVE]: this.removeProcessingItem.bind(this)
			}[processingItem.action]
			mergedItems = modifyFn(mergedItems, processingItem.data)
			this.processingItems = this.processingItems.filter(item => item !== processingItem)
		})

		return mergedItems
	}

	addProcessingProduct (items, { product, quantity }) {
		let exist = false
		const merged = items
			.slice()
			.map(item => {
				if (item.product.id !== product.id) return item

				item.quantity += quantity
				exist = true
				return item
			})

		if (exist) return merged

		merged.push(new CartItem({
			app: this.app,
			cart: this,
			product,
			quantity
		}))

		return merged
	}

	removeProcessingItem (items, { item }) {
		return items
			.slice()
			.filter(object => object !== item)
	}

	async sendData (itemsToSend) {
		if (!this.id) return await this.create(itemsToSend)
		return await this.service.updateCart(this.id, itemsToSend)
	}

	async create (itemsToSend) {
		const data = await this.service.createCart(itemsToSend)

		if (data.error) throw Error(data.error)

		this.setId(data.id)
		return data
	}

	setId (id) {
		this.id = id
		cookies.set(CART_COOKIE, this.id, { expires: 365 })
	}

	async removeItem (item) {
		this.app._emit('cart:product:removed', item)
		this.processingItems.push({
			action: cartActions.REMOVE,
			data: {
				item
			}
		})
		await this.update()
	}

	async applyDiscountCode (code) {
		if (!this.id) await this.update()

		const data = await this.service.addDiscountCodeToCart(code, this.id)

		await this.fetch()

		return data
	}

	async removeDiscountCode () {
		const data = await this.service.removeDiscountCodeToCart(this.id)

		await this.fetch()

		return data
	}

	clear () {
		cookies.remove(CART_COOKIE)
		this.id = undefined
		this.amount = undefined
		this.amountBeforeDiscount = undefined
		this.leftToFreeDelivery = undefined
		this.items = []
		this.discountCode = null
		this.processingItems = []
		this.loading = false
	}

	async createDraftOrder () {
		const clientId = this.app.authorization.client
		const data = await this.service.createDraftOrder({
			cartId: this.id,
			clientId
		})

		const DraftOrder = this.app.getEntity('draft-order')
		const draftOrder = new DraftOrder(data)
		return draftOrder
	}
}

export { Cart, cartActions, CART_COOKIE }
