import { ReactiveClass } from '@f/core/ReactiveClass'
class CartItem extends ReactiveClass {
	cart
	product
	_quantity
	amountAfterDiscount
	amountBeforeDiscount

	constructor ({ app, cart, product, quantity, amountAfterDiscount, amountBeforeDiscount }) {
		super()
		this.cart = cart
		this._quantity = this.prepareQuantity(quantity)
		this.amountAfterDiscount = amountAfterDiscount
		this.amountBeforeDiscount = amountBeforeDiscount

		const Product = app.getEntity('product')
		if (product instanceof Product) this.product = product
		else this.product = Product.create(product)

		this.initReactive()
	}

	get priceForSingleProductAfterDiscount () {
		return this.amountAfterDiscount / this.quantity
	}

	set quantity (value) {
		this._quantity = this.prepareQuantity(value)
		this.cart.update()
	}

	get quantity () {
		return this._quantity
	}

	prepareQuantity (value) {
		value = parseInt(value)
		if (value < 1) return 1
		if (value > 100) return 100
		return value
	}

	remove () {
		this.cart.removeItem(this)
	}

	toObject () {
		return {
			product: this.product.id,
			quantity: this.quantity
		}
	}
}

export { CartItem }
