
<template>
	<component
		:is="tag"
		:href="path"
		class="base-button-styles"
		:class="[
			type !== 'product' ? `btn btn-${type}` : 'btn-product',
			fullWidth && 'full-width',
			marginReset ? 'margin-reset' : '',
			disabled ? 'btn-disabled' : ''
		]"
		:disabled="disabled"
		@click.prevent="handleClick()"
	>
		<slot v-if="leftSlot" name="left" />
		<slot />
		<slot v-if="rightSlot" name="right">
			<base-svg class="right-icon"  name="btn_arrow"/>
		</slot>
	</component>
</template>

<script>
import BaseSvg from '@f/components/BaseSvg'
import { NavigationMixin } from '~/cms/front/mixins/NavigationMixin'

export default {
	components: {
		BaseSvg
	},
	mixins: [NavigationMixin],
	props: {
		type: {
			type: String,
			default: 'primary'
		},
		rightSlot: {
			type: Boolean,
			default: true
		},
		marginReset: {
			type: Boolean,
			default: false
		},
		fullWidth: {
			type: Boolean,
			default: false
		},
		leftSlot: {
			type: Boolean,
			default: true
		},
		disabled: {
			type: Boolean,
			default: false
		}
	}
}
</script>
