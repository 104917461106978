import { Seo } from '../seo/Seo'
class Page {
	static alias = 'default'

	static checkRoute (to) {
		return true
	}

	visible = () => true
	component
	componentsData = {}

	app
	_value = {}
	route
	from
	seo
	statusCode

	get params () {
		return this.route.params
	}

	get query () {
		return this.route.query
	}

	get alias () {
		return this.route.params.alias || this.route.meta.alias
	}

	get value () {
		return this._value
	}

	set value (value) {
		this._value = value ?? {}
	}

	get layout () {
		return this.app.layout
	}

	set layout (layout) {
		this.app.layout = layout
	}

	get language () {
		if (this._language) return this._language
		return this.route.meta.language
	}

	set language (language) {
		this._language = language
	}

	constructor ({ app, route, from }) {
		this.app = app
		this.route = route
		this.from = from
		this.seo = new Seo(app, { page: this })
	}

	async init () {
		await this.fetch()
	}

	async fetch () {
		if (this.from.meta.alias || process.server) await this.fetchData()
		else this.restore()
	}

	async fetchData () {
		const data = await this.app.getService('rext').getPage(this.alias)
		await this.setPageData(data)
	}

	async setPageData (data) {
		this.createdAt = data.createdAt
		this.updatedAt = data.updatedAt
		this.value = data.value
		this.meta = data.meta
		this.layout = this.app.getLayout(data.layout)
		if (this.layout) await this.layout.fetch()
	}

	restore () {
		this.componentsData = window.__PAGE__.componentsData
		this.value = window.__PAGE__.value
		this.layout = this.app.getLayout(window.__PAGE__.layout)
		if (this.layout) this.layout.restore()
	}

	store () {
		return {
			componentsData: this.componentsData,
			value: this.value,
			layout: this.layout ? this.layout.alias : undefined
		}
	}

	afterRedirect () {}
}

export { Page }
