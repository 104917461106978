class DraftOrder {
	static async findById (id) {
		const rextService = this.app.getService('rext')
		const data = await rextService.getDraftOrder(id)

		if (data.error) {
			return false
		}

		const DraftOrder = this.app.getEntity('draft-order')
		return new DraftOrder(data)
	}

	constructor (data) {
		if (data) this.setData(data)
	}

	setData (data) {
		Object.entries(data).map(([key, value]) => {
			this[key] = value
		})
	}

	async getDeliveries () {
		const rextService = this.constructor.app.getService('rext')
		const data = await rextService.getDeliveriesForDraftOrder(this.id)

		return data
	}

	async getPaymentMethods () {
		const rextService = this.constructor.app.getService('rext')
		const data = await rextService.getPaymentMethodsForDraftOrder(this.id)

		return data
	}

	async update (data) {
		const rextService = this.constructor.app.getService('rext')
		const response = await rextService.updateDraftOrder(this.id, data)

		return response
	}

	async createOrder () {
		const rextService = this.constructor.app.getService('rext')
		const data = await rextService.createOrderFromDraftOrder(this.id)

		return data
	}
}

export { DraftOrder }
