<template>
	<div class="box-wrapper">
		<div class="wrapper" v-if="isAuth">
			<topbar-close @close="close">{{name}}</topbar-close>
			<base-button to="user-profile" :params="{view: userProfileViews.orders }" @click="close" class="profile-nav" type="secondary">
				{{langs.orders}}
			</base-button>
			<base-button to="user-profile" :params="{view: userProfileViews['client-details'] }" @click="close" class="profile-nav" type="secondary">
				{{langs.settings}}
			</base-button>
			<base-button @click="logout" class="profile-nav" type="white">
				{{langs.logout}}
			</base-button>
		</div>
		<div class="wrapper" v-else>
			<topbar-close @close="close">{{langs.login}}</topbar-close>
			<base-button to="auth" :params="{ view: authViews.signIn }" class="sign-in-button" type="secondary">
				{{langs.login}}
			</base-button>
			<base-font>{{langs.dont_have_account}}</base-font>
			<base-button to="auth" :params="{ view: authViews.signUp }" class="sign-up-button" type="secondary">
				{{langs.signUp}}
			</base-button>
		</div>
	</div>
</template>
<script>

import TopbarClose from '~/website/front/components/molecules/TopbarClose'
import { AuthViews } from '~/authorization/front/core/pages/Auth/Auth'
import { UserProfileViews } from '~/shop/front/core/pages/UserProfile/UserProfile'

export default {
	components: {
		TopbarClose
	},
	computed: {
		langs () {
			return this.$app.translator.get('signBox')
		},
		isAuth () {
			if (this.$app.authorization.isAuthorizated) return true
			else return false
		},
		authViews () {
			return AuthViews
		},
		userProfileViews () {
			return UserProfileViews
		},
		name () {
			return this.langs.profile.replace('<<name>>', this.$app.authorization.client?.firstName)
		}
	},
	methods: {
		close () {
			this.$emit('closeOverlay')
		},
		logout () {
			this.$app.authorization.signOut()
		}
	},
	async prefetch () {
		await this.$app.translator.prefetch('signBox')
	}
}
</script>
<style lang="scss" scoped>
.wrapper {
	width: 415px;
	display: flex;
	flex-direction: column;
	align-items: center;
	background-color: $white;
	box-shadow: $secondary-shadow;
	& .sign-in-button {
		position: relative;
		margin-top: 1rem;
		margin-bottom: 3.5rem;
		&::before {
			content: '';
			position: absolute;
			bottom: -3.5rem;
			width: 360px;
			height: 1px;
			left: -75px;
			background-color: $gray-100;
		}
	}
	& .sign-up-button {
		margin-top: 2rem;
	}
	& .profile-nav {
		margin: 1rem 0;
		min-width: 277px;
		justify-content: center;

		&:last-of-type {
			margin-bottom: 3rem;
		}
	}
}
.title-wrapper {
	position: relative;
	width: 100%;
}
</style>
