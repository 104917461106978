<template>
	<footer class="footer">
		<base-container>
			<base-row>
				<base-col col="12" md="2" lg="3" xxl="2" class="btn-wrapper">
					<base-button @click="goBack" type="white" :disabled="isReturnPageAnActualPage" :rightSlot="true" class="btn-back" marginReset>
						<base-font color="primary" clear tag="span">{{$app.layout.value.back_button}}</base-font>
					</base-button>
				</base-col>
				<base-col col="12" md="5" lg="4" xl="3">
					<div class="footer-number-wrapper">
						<cms-text class="footer-number-text" layout :props="{size: 'sm', marginReset: true}" value="footer.footer_number_label"/>
						<base-link type="black" class="phone" :url="`tel:${number}`">
							<base-svg name="phone" class="phone-icon"/>
							<base-font size="sm" tag="span" class="phone-number" >{{number}}</base-font>
						</base-link>
					</div>
				</base-col>
				<base-col col="12" md="5" lg="5" xxl="4" offset-xl="1" offset-xxl="3">
					<div class="footer-menu-wrapper">
						<base-link
							class="footer-menu-item"
							v-for="item in footerMenu"
							:key="item.name"
							type="primary"
							underline
							:to="item.to"
							target="_blank"
						>
							{{item.name}}
						</base-link>
					</div>
				</base-col>
			</base-row>
		</base-container>
	</footer>
</template>
<script>
import { getPath } from 'utils/getPath'
import { AuthViews } from '~/authorization/front/core/pages/Auth/Auth'

export default {
	computed: {
		footerMenu () {
			return this.$app.layout.value.footer.footer_items
		},
		number () {
			return this.$app.getSettings().general.contact.phoneNumber
		},
		isReturnPageAnActualPage () {
			return this.$app.vue.$route.meta.alias === 'return'
		},
		isUserAuthorized () {
			return this.$app.authorization.isAuthorizated
		}
	},
	methods: {
		goBack () {
			const checkoutProgress = this.$app.layout.value['checkout-progress'].checkout_progress
			const checkoutProgressCurrentRoute = checkoutProgress.find(route => route.to === this.$app.vue.$route.meta.alias)
			const routeIndex = checkoutProgress.indexOf(checkoutProgressCurrentRoute) - 1

			if (routeIndex === 0 && this.isUserAuthorized) {
				this.$app.vue.$router.push(getPath({
					app: this.$app,
					to: checkoutProgress[routeIndex].to
				}))
			} else if (routeIndex === 0 && !this.isUserAuthorized) {
				this.$app.vue.$router.replace(getPath({
					app: this.$app,
					to: 'auth',
					params: {
						view: AuthViews.signIn
					},
					query: {
						checkout: true
					}
				}))
			} else {
				this.$app.vue.$router.go(-1)
			}
		}
	}

}
</script>
<style lang="scss" scoped>
	.footer {
		border-top: 1px solid $gray-600;
		margin-top: auto;

		:deep(.btn-wrapper) {
			margin-top: 1rem;
			margin-bottom: 2rem;
			justify-content: center;
			display: flex;
			@include media-breakpoint-up(md) {
				margin-block: 0;
				align-items: center;
			}
			@include media-breakpoint-up(lg) {
				margin-block: 0;
				justify-content: flex-start;
			}

		}
	}
	.footer-number-wrapper {
		display: flex;
		flex-direction: column;
		align-items: center;
		margin-bottom: 1rem;
		@include media-breakpoint-up(md) {
			height: 100%;
			justify-content: center;
		}
		@include media-breakpoint-up(lg) {
			margin-bottom: 0;
			align-items: flex-start;
		}
	}
	.phone {
		display: flex;
		align-items: center;
		justify-content: center;

		& :deep(.phone-number) {
			margin: 0 0 0 5px;
			@include media-breakpoint-up(lg) {
				margin: 0 0 0 15px;
			}
		}
		&-icon {
			width: 17px;
			height: 17px;

			@include media-breakpoint-up(lg) {
				width: 20px;
				height: 20px;
			}
		}
	}
	.footer-menu-wrapper {
		display: flex;
		align-items: center;
		flex-direction: column;
		justify-content: space-between;
		height: 100%;
		text-align: center;

		.footer-menu-item {
			margin-block: 1rem;

			@include media-breakpoint-up(lg) {
				margin-block: 0;
			}
		}
		@include media-breakpoint-up(lg) {
			flex-direction: row;
		}
		@include media-breakpoint-up(xxl) {
			text-align: left;
		}
	}

</style>
