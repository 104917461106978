import { Input } from './Input'
import InputTextComponent from '~/website/front/components/molecules/fileds/FieldText'

class InputText extends Input {
	component = InputTextComponent
	_mask = false

	constructor ({ mask = false, ...data }) {
		super(data)
		this._mask = mask
	}

	get mask () {
		if (typeof this._mask === 'function') return this._mask()
		return this._mask
	}
}

export { InputText }
