<template>
	<footer class="footer">
		<base-container>
			<base-row>
				<base-col sm="12" lg="3">
					<!-- <cms-image :base="{width: 210}" class="footer-logo" value="logo" layout/> -->
					<base-svg class="footer-logo" name="nimphy_logo"/>
				</base-col>
				<base-col class="footer-company-info" sm="12" md="4" lg="3">
					<div class="footer-contacts">
						<div class="footer-item-icon">
							<base-svg class="footer-icon" name="email"/>
							<base-link type="black" :url="`mailto:${contact.email}`" target="_self">
								<base-font>{{ contact.email }}</base-font>
							</base-link>
						</div>
					</div>
				</base-col>
				<base-col col="6" md="4" lg="3" >
					<div class="footer-links-wrapper">
						<base-link
							v-for="item in leftColumn"
							:key="item.name"
							type="primary"
							underline
							:to="item.to"
						>
							{{item.name}}
						</base-link>
					</div>
				</base-col>
				<base-col col="6" md="4" lg="3">
					<div class="footer-links-wrapper">
						<base-link
							v-for="item in rightColumn"
							:key="item.name"
							type="primary"
							underline
							:to="item.to"
						>
							{{item.name}}
						</base-link>
					</div>
				</base-col>
			</base-row>
		</base-container>
	</footer>
</template>
<script>
export default {
	computed: {
		menu () {
			return this.$app.layout.value.menu
		},
		leftColumn () {
			return this.menu.items.slice(0, 4)
		},
		rightColumn () {
			return [...this.menu.items.slice(4), ...this.menu.additionalLinks]
		},
		contact () {
			return this.$app.getSettings().general.contact
		}
	}
}

</script>
<style lang="scss" scoped>
.footer {
	border-top: 1px solid #e5e5e5;
	padding: 3rem 0 2rem;
	@include media-breakpoint-up(lg) {
		padding: 6rem 0 4rem;
	}
	:deep(.footer-company-info) {
		p {
			line-height: 18.4px;
		}
	}
	&-contacts {
		margin-bottom: 4.5rem;
		@include media-breakpoint-up(md) {
			margin-bottom: 0;
		}
	}
	:deep(.footer-logo) {
		width: 210px;
		display: block;
		padding-top: 2rem;
		margin-bottom: 4.5rem;
		@include media-breakpoint-up(md) {
			margin-bottom: 2.5rem;
		}
		@include media-breakpoint-up(lg) {
			margin-bottom: 0;
			padding-top: 1rem;
		}
	}
	&-address-data {
		margin-bottom: 2rem;
		margin-top: -2px;
		@include media-breakpoint-up(md) {
			margin-bottom: .5rem;
		}
		& p + p {
			margin-left: .5rem;
		}
	}
	&-street-address {
		display: flex;
		gap: 5px;
		@include media-breakpoint-up(xl) {
			margin: 1rem 0;
		}
	}
	&-links-wrapper {
		display: flex;
		flex-direction: column;
		text-align: left;

		& a {
			font-weight: bold;
			display: block;
			margin-bottom: 1rem;
			@include media-breakpoint-up(lg) {
				font-weight: 500;
			}
		}
	}
	&-company-info {
		margin-top: 4.5rem;
		margin-bottom: 5rem;
		@include media-breakpoint-up(lg) {
			margin-top: 0;
			margin-bottom: 0;
		}
	}
	&-item-icon {
		display: flex;
		align-items: center;
		.footer-icon {
			width: 18px;
			height: 12px;
			margin-right: 1rem;
			flex-shrink: 0;
		}
	}
}
</style>
