<template>
	<div class="product" :class="{ 'product-slim': slim, 'horizontal-product': horizontalSlider  }">
		<base-link :type="false" @click="productClickedHandler" to="product" class="product-link"  :params="{alias: product.alias}">
			<div class="badge-wrapper">
				<base-badge v-for="tag in product.tags" :key="tag" :type="tag" />
			</div>
			<cms-image
				class="product-image"
				lazy
				:src="thumbnail"
				:base="{width: 175, height: 110}"
				:sm="{width: 250, height: 150}"
				:md="{width: 330, height: 217}"
				:xl="{width: 330, height: 217}"
			/>
			<div class="capacity-badge-wrapper" v-if="product.capacity">
				<base-badge :type="product.capacity" />
			</div>
		</base-link>
		<div class="product-details">
			<base-link class="product-title-link" @click="productClickedHandler" to="product" :params="{alias: product.alias}">
				<base-font class="product-title" variant="small-header" tag="h2">{{ product.name }}</base-font>
			</base-link>
			<div class="product-content" v-if="!slim">
				<base-link @click="productClickedHandler" to="product" :params="{alias: product.alias}" class="product-info-link" type="product-info" underline>
					{{langs.info_link}}
				</base-link>
			</div>
		</div>
		<div class="product-bottom">
			<div class="product-price-wrapper" :class="{ 'product-price-wrapper-discount-mobile': isMobileAndDiscount }">
				<div class="badge-promotion" v-show="product.isDiscount">
					<div class="badge-content" >
						<base-price class="price-before-discount" :price="product.priceBeforeDiscount" />
					</div>
				</div>
				<base-font variant="price" tag="div"><base-price :price="product.price"/></base-font>
			</div>
			<product-button @click="product.addToCart()">{{langs.add_to_cart}}</product-button>
		</div>
	</div>
</template>

<script>
import BaseBadge from '../BaseBadge'
import ProductButton from '../ProductButton'

export default {
	props: {
		product: Object,
		slim: Boolean,
		horizontalSlider: Boolean
	},
	computed: {
		langs () {
			return this.$app.translator.get('products')
		},
		thumbnail () {
			const thumbnail = this.product.thumbnail

			if (!thumbnail.title) thumbnail.title = this.product.name
			if (!thumbnail.alt) thumbnail.alt = this.product.name
			return thumbnail
		},
		isMobileAndDiscount () {
			return this.$app.utils.mobile && this.product.isDiscount
		}
	},
	components: {
		BaseBadge,
		ProductButton
	},
	async prefetch () {
		await this.$app.translator.prefetch('products')
	},
	methods: {
		productClickedHandler () {
			this.$app._emit('product:clicked', this.product)
		}
	},
	mounted () {
		this.$app._emit('product:impression', this.product)
	}
}
</script>
<style lang="scss" scoped>
.product {
	position: relative;
	display: flex;
	flex-direction: column;
	height: 100%;
	max-width: 335px;
	margin: 0 auto;
	box-shadow: $base-shadow;
	background-color: white;
	overflow: hidden;
	& :deep(.product-title) {
		margin-top: 2rem;
		min-height: 49px;
		font-size: 1.4rem;

		@include media-breakpoint-up(sm) {
			font-size: 1.6rem;
		}

		@include media-breakpoint-up(md) {
			font-size: 1.8rem;
		}
	}
	@include media-breakpoint-up(md) {
		max-height: 460px;

		&-link {
			cursor: pointer;
		}

	}
	&-link {
		display: flex;
		flex-direction: column;
		flex-grow: 0;
		color: inherit;
		text-decoration: none;
		position: inherit;
	}
	&-title-link {
		margin-bottom: 1rem;
		padding-bottom: 1rem;
		word-break: break-word;
	}
	p {
		line-height: 3rem;
	}
	&-image {
		display: flex;
		align-items: center;
		position: relative;
		height: 200px;
		width: 100%;
		justify-content: center;
		background: #F7F7F7;

		@include media-breakpoint-up(md) {
			display: block;
			height: fit-content;
		}

		& :deep(img) {
			display: block;
			width: 100%;
		}
	}
	&-details {
		display: flex;
		flex-direction: column;
		flex-grow: 1;
		justify-content: space-between;
		padding: 0 1rem;

		@include media-breakpoint-up(md) {
			padding: 0 2rem;
		}
	}
	&-content {
		position: relative;
		&::before {
			position: absolute;
			content: '';
			height: 1px;
			top: -20px;
			left: 0;
			right: 0;
			background-color: $gray-500;
		}
	}
	&-info-link {
		margin: 0 0 2.5rem;
		text-decoration: underline;
	}
	&-bottom {
		display: flex;
		flex-direction: column;
		align-items: center;
		background-color: $gray-200;

		@include media-breakpoint-up(md) {
			flex-direction: row;
		}

		:deep(.btn-plus-icon) {
			height: 21px;
			width: 21px;
			margin-right: 1rem;
		}
	}
	&-price-wrapper {
		position: relative;
		text-align: center;
		height: 100%;
		min-width: 90px;
		height: 30px;
		margin: 1rem 0;

		@include media-breakpoint-up(xxl) {
			min-width: 120px;
			margin: 0;
		}
	}
	&-bottom &-price-wrapper-discount-mobile {
		display: flex;
		.badge-promotion {
			position: static;
			top: auto;
			left: auto;

			.badge-content {
				margin-top: 5px;
				margin-left: -5px;
			}

			&:before {
				width: 50%;
			}
		}
	}
	&-slim {
		min-height: initial;
		width: auto;
		.product-title-link {
			margin-bottom: 0;
			padding-bottom: 0;
		}
	}
	&.horizontal-product {
		flex-shrink: 0;
		max-width: 270px;
		margin: 0;
		@include media-breakpoint-up(md) {
			max-width: 250px;
			min-height: 340px;
		}
		@include media-breakpoint-up(xl) {
			max-width: 280px;
			min-height: none;
		}
	}
	.badge-wrapper {
		position: absolute;
		top: 0;
		z-index: 1;
	}
	.capacity-badge-wrapper {
		position: absolute;
		top: 170px;
		z-index: 2;

		@include media-breakpoint-up(md) {
			top: 150px;
		}
		@include media-breakpoint-up(xl) {
			top: 120px;
		}
		@include media-breakpoint-up(xxl) {
			top: 150px;
		}
	}
	.badge-promotion {
		position: absolute;
		top: -30px;
		left: -5px;
		z-index: 2;

		&::before {
			content: '';
			position: absolute;
			top: 50%;
			transform: translateY(-50%) skew(-15deg);
			left: 0;
			width: 100%;
			height: 3rem;
			background: $gold;
			z-index: -1;
		}
		.badge-content {
			color: $white;
			font-size: 1.4rem;
			font-family: $secondary-font;
			line-height: 2rem;
			min-width: 80px;
			.price-before-discount {
				position: relative;
				&::before {
					position: absolute;
					content: '';
					top: 50%;
					left: 50%;
					height: 1px;
					width: 120%;
					background-color: $white;
					transform: translate(-50%, -50%) rotate(-5deg);
				}
			}
		}
	}
}
</style>
