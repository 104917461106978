<template>
	<base-container>
		<div class="header-wrapper">
			<cms-image
				class="image"
				:src="src"
				:base="{ width: 575, height: 150 }"
				:md="{ width: 690, height: 150 }"
				:lg="{ width: 930, height: 150 }"
				:xl="{ width: 1390, height: 150 }"
				lazy
			/>
			<base-font
				class="header"
				variant="hero-header-medium"
				tag="p"
				color="white">
			{{title}}</base-font>
		</div>
	</base-container>
</template>
<script>
export default {
	computed: {
		src () {
			return this.$app.page.value.headerImage ? this.$app.page.value.headerImage : this.$app.layout.value.defaultHeaderImage
		},
		title () {
			return this.$app.page.header.title
		}
	}
}
</script>
<style lang="scss" scoped>
	.header-wrapper {
		position: relative;
		width: 100%;
		min-height: 15rem;
		display: flex;
		align-items: center;
		overflow: hidden;

		&::after {
			content: '';
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
			width: calc(100% - 2rem);
			height: calc(100% - 2rem);
			border: .1rem solid $white;
		}

		& :deep(.header) {
			margin-left: 2rem;
			letter-spacing: 0.04em;
			max-width: calc(100% - 3rem);
			@include media-breakpoint-up(lg) {
				margin-left: 4rem;
			}
			@include media-breakpoint-up(xl) {
				margin-left: 10rem;
			}
		}
		& :deep(.image) {
			position: absolute;
			z-index: -1;
		}
		& :deep(.image-wrapper) {
			&::after{
				content: '';
				height: 100%;
				width: 100%;
				background-color: rgba(0,0,0,0.4);
				position: absolute;
				top: 0;
				left: 0;
				z-index: 0;

			}

		}
	}
</style>
