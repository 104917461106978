<script>
import BaseLink from '~/cms/front/components/BaseLink'
export default {
	extends: BaseLink,
	name: 'BaseLink',
	globalComponent: true
}
</script>

<style lang="scss" scoped>
.link {
	color: $primary;
	text-transform: uppercase;
	cursor: pointer;
	font-size: 1.4rem;
	@include transition;
	@include media-breakpoint-up(md) {
		font-size: 1.6rem;
	}
	&.underline {
		text-decoration: underline;
	}
	&.no-underline {
		text-decoration: none;
	}
	&-gold {
		text-transform: initial;
		color: $gold;
		font-weight: bold;
		&:hover {
			color: $primary;
		}
	}
	&-black {
		text-transform: initial;
		color: $primary;
		font-weight: bold;
		&:hover {
			color: $gold;
		}
	}
	&-primary {
		font-weight: 500;
		&:hover {
			color: $gold;
		}
	}
	&-secondary {
		font-weight: bold;
		font-family: 'DM Serif Display', sans-serif;
		text-transform: initial;
		color: $white;
		&:hover {
			color: $gold;
		}
	}
	&-white {
		text-transform: initial;
		font-weight: bold;
		color: $white;
		&:hover {
			color: $gold;
		}
	}
	&-product-info {
		display: flex;
		font-weight: bold;
		&:hover {
			color: $gold;
			.svg-icon:deep(path){
				fill: $gold
			}
		}
		& svg {
			order: -1;
			margin-right: 1rem;
			width: 21px;
			height: 21px;
		}
	}
	&-gray {
		font-weight: 500;
		color: $gray-800;

		&:hover {
			color: darken($gray-800, 30%)
		}
	}
	&-navigation {
		padding: 2rem 3rem 2rem 2rem;
		background: $white;
		text-transform: uppercase;
		font-weight: 500;
		display: inline-block;
		min-width: 250px;
		text-align: center;
		@include media-breakpoint-up(lg) {
			text-align: left;
		}
		&.active {
			background: $green-600;
			color: $white;
		}
		@media (hover: hover) {
			&:hover {
				min-width: 265px;
			}
		}
	}
}
</style>
