<template>
	<div v-if="modal">
		<div class="modal" :class="{'big-modal': modal.isBig, 'inpost-modal': isInpostModal}">
			<topbar-close @close="close" > {{modal.title}} </topbar-close>
			<div class="content">
				<component :is="modal.component" :modal="modal"/>
			</div>
		</div>
		<div class="overlay"></div>
	</div>
</template>

<script>
import TopbarClose from '~/website/front/components/molecules/TopbarClose'
import { InpostModal } from '~/shop/front/components/InpostModal/InpostModal'
export default {
	components: {
		TopbarClose
	},
	computed: {
		modal () {
			return this.$app.modal
		},
		isInpostModal () {
			return this.modal instanceof InpostModal
		}
	},
	methods: {
		close () {
			this.modal.close()
		}
	}
}
</script>

<style lang="scss" scoped>
.modal {
	position: fixed;
	top: 50%;
	transform: translateY(-50%);
    z-index: $level3;
	width: 101%;
	height: 101%;
	display: flex;
	flex-direction: column;
	background: white;
	overflow: hidden;
	border-radius: $border-radius;
	-ms-overflow-style: none;
	scrollbar-width: none;
	&.big-modal {
		width: 50vw;
		height: 90vh;
	}
	&::-webkit-scrollbar {
		display: none;
	}

	@include media-breakpoint-up(md) {
		top: 50%;
		left: 50%;
		width: fit-content;
		max-height: 690px;
		height: 75%;
		transform: translate(-50%, -50%);
		width: 80%;
	}
	@media screen and (min-width: 550px) and (max-width: 1023px) and (orientation: landscape) {
		width: 101%;
		height: 101%;
		overflow-y: scroll;
	}
	@include media-breakpoint-up(lg) {
		max-height: 570px;
	}
	@include media-breakpoint-up(xl) {
		top: 55%;
		height: fit-content;
		max-height: none;
	}
	@include media-breakpoint-up(xxl) {
		width: 60%;
	}
	@media screen and (min-width: 1620px) {
		top: 50%;
	}
	@media screen and (min-width: 2000px){
		width: 40%;
	}

	.content {
		width: 100%;
		overflow-y: auto;
		overflow-x: hidden;
		padding: 0 20px;
		-ms-overflow-style: none;
		scrollbar-width: none;

		&::-webkit-scrollbar {
			display: none;
		}

		@include media-breakpoint-up(lg) {
			overflow-y: initial;
			padding: 0 30px;
		}
	}
}
.overlay {
	position: fixed;
	top: 0;
	left: 0;
	width: 100vw;
	height: 100vh;
	z-index: $level2;
	background-color: rgba($black, 0.35);
	backdrop-filter: blur(4px);
}
.inpost-modal {
	@include media-breakpoint-up(xl) {
		top: 50%;
	}
	@include media-breakpoint-up(xxl) {
		width: 70%;
	}

	@media screen and (min-width: 2000px){
		width: 40%;
	}

	.content {
		height: 100%
	}
}

</style>
