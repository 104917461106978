<template>
	<div class="cart-item" :class="{ focus: isFocus, 'cart-wide': isWide }">
		<cms-image
			class="cart-item-image"
			:src="item.product.thumbnail"
			:base="{width: 45, height: 45}"
			:xl="{width: isWide ? 100 : 65, height: isWide ? 100 : 65}"
		/>
		<div class="cart-item-main-data">
			<div>
				<base-font class="cart-item-title" weight="bold" uppercase>{{ item.product.name }}</base-font>
				<div class="cart-item-quantity">
					<span>{{ item.quantity }}</span>
					<span>szt</span>
				</div>
			</div>
			<skeleton-loader :isVisible="loading">
				<div class="cart-item-price" >
					<div v-if="item.quantity > 1" class="cart-item-single-price">
						<span>{{ item.quantity }}</span>
							x
						<base-price :price="item.product.price"/>
					</div>
					<base-price class="cart-item-full-price" :price="item.amountAfterDiscount"/>
				</div>
			</skeleton-loader>
		</div>
		<div class="cart-item-hidden">
			<field-quantity v-model="item.quantity" @is-focused="setFieldFocus" />

			<div class="cart-item-icon cart-item-icon-info">
				<base-link class="product-link" to="product" :params="{alias: item.product.alias}">
					<base-font class="product-link-text" v-if="!isWide" weight="bold">Zobacz</base-font>
				</base-link>
			</div>
			<div class="cart-item-icon cart-item-icon-delete" @click="item.remove()">
				<base-svg name="trash_product"/>
			</div>
		</div>
	</div>
</template>
<script>
import FieldQuantity from '../FieldQuantity'
import SkeletonLoader from '~/website/front/components/molecules/loaders/SkeletonLoader'

export default {
	props: {
		item: Object,
		loading: {
			type: Boolean,
			default: false
		},
		isWide: {
			type: Boolean,
			default: false
		}
	},
	data: () => ({
		isFocus: false
	}),
	components: {
		FieldQuantity,
		SkeletonLoader
	},
	methods: {
		setFieldFocus (state) {
			this.isFocus = state
		}
	}
}
</script>
<style lang="scss" scoped>
.cart-item {
	position: relative;
	display: flex;
	padding-block: 2rem;
	&::before {
		position: absolute;
		content: '';
		bottom: 0px;
		left: 0;
		width: 100%;
		height: 1px;
		background-color: $gray-500;
	}
	& :deep(.cart-item-title) {
		width: 160px;
		line-height: 1.9rem;
	}
	&-quantity {
		margin-top: 1rem;
		font-size: 1.4rem;
		opacity: .3;
		span:first-child {
			font-weight: bold;
			margin-right: .5rem;
		}
	}
	.cart-wide &-quantity {
		display: none;
		@include media-breakpoint-up(lg) {
			display: inline-block;
		}
	}
	& :deep(.cart-item-image){
		margin-right: 2rem;
		display: block;
	}
	&-price {
		font-family: $secondary-font;
		display: flex;
		flex-direction: column;
		align-items: flex-end;
		margin-left: auto;
	}
	.cart-wide &-price {
		height: 100%;
		margin-right: 2rem;
		margin-left: 0;
		align-items: flex-start;
		@include media-breakpoint-up(lg){
			justify-content: flex-end;
			align-items: flex-end;
		}
	}
	&-single-price {
		color: $gray-800;
		font-size: 1.3rem;
	}
	.cart-wide &-single-price {
		margin-top: 2rem;
	}
	&-full-price {
		font-size: 2rem;
	}
	&-icon {
		& .svg-icon {
			margin-left: 1rem;
			width: 18px;
		}
		&-info {
			position: relative;
			width: 100px;
			& :deep(.product-link) {
				display: flex;
				align-items: center;
				text-decoration: none;
				.product-link-text {
					@include transition;
				}
			}
			&::before {
				content: '';
				position: absolute;
				right: 0;
				top: -15px;
				height: 50px;
				width: 1px;
				background-color: $gray-500;
			}
		}
		.cart-wide &-info .product-link-text {
			display: none;
		}
		@include media-breakpoint-up(xl) {
			&.cart-item-icon-delete {
				cursor: pointer;
			}
			&:hover {
				& :deep(.product-link) {
					.product-link-text {
						color: $gold;
					}
				}
				&.cart-item-icon-info :deep(path) {
					fill: $gold;
				}
				&.cart-item-icon-delete :deep(path) {
					fill: $primary;
				}
			}
		}
	}
	&.cart-wide .cart-item-icon-info {
		width: min-content;
		&::before {
			content: none;
		}
	}
	&:not(.cart-wide) &-hidden {
		display: flex;
		align-items: center;
		justify-content: space-between;
		position: absolute;
		right: 0;
		left: 65px;
		top: 50%;
		transform: translateY(-50%);
		opacity: 0;
		height: 100%;
		@include transition;

		@include media-breakpoint-up(xl) {
			left: 85px;
		}
		@include media-breakpoint-up(xxl) {
			left: 90px;
		}
	}
	&.cart-wide &-hidden {
		display: flex;
		flex-direction: column-reverse;
		align-items: flex-end;
		justify-content: space-between;

		.cart-item-icon-info {
			margin-block: 1rem;
		}
	}
	&-main-data {
		display: flex;

		justify-content: space-between;
		width: 100%;
		@include transition;

	}
	.cart-wide &-main-data {
		flex-direction: column;
		@include media-breakpoint-up(lg){
			flex-direction: row;
		}
	}
	&.focus:not(.cart-wide), &:hover:not(.cart-wide) {
		.cart-item-main-data {
			opacity: 0;
			& + .cart-item-hidden {
				opacity: 1;
			}
		}
	}
}
</style>
