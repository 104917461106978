<script>
import BaseButton from '~/cms/front/components/BaseButton'

export default {
	extends: BaseButton,
	name: 'BaseButton',
	globalComponent: true
}
</script>

<style lang="scss" scoped>
.base-button-styles {
	display: flex;
	align-items: center;
	position: relative;
	justify-content: center;
	text-transform: uppercase;
	color: $white;
	font-weight: 500;
	cursor: pointer;
}
.btn {
	width: 100%;
	padding: 2rem 3rem;
	margin: 3rem 0 6rem 0;
	font-family: $primary-font;
	letter-spacing: 0.0041em;
	line-height: 1.9rem;
	border: 1px solid transparent;
	outline: 0 solid rgba($gold, .3);
	outline-offset: 0;
	text-decoration: none;

	& * {
		display: block;
		line-height: 1.9rem;
		font-size: 1.5rem;
		@include media-breakpoint-up(xxl) {
			font-size: 1.6rem;
		}
	}

	@include transition;
	& .right-icon {
		display: none;

	}
	&-primary {
		background-color: rgba($black, .33);
		border-color: $gold;
	}
	&-secondary {
		background-color: $gold;
		@include media-breakpoint-up(xl) {
			&:hover:not(:disabled) {
				background-color: $gold-600;
			}
		}
	}
	&-white {
		background-color: $white;
		color: $primary;
		border-color: $primary;
		& :deep(svg path) {
			stroke: $primary;
		}
		@include media-breakpoint-up(xl) {
			&:hover:not(:disabled) {
				background-color: rgba($black, .06);
			}
		}
	}

	@include media-breakpoint-up(md) {
		width: auto;
	}
	@include media-breakpoint-up(xl) {
		padding: 2rem 6rem;
		&:hover:not(:disabled) {
			padding: 2rem 7rem 2rem 5rem;
			outline-width: 1px;
			outline-offset: 7px;
			& .right-icon {
				transform: translateX(15px);
				opacity: 1;
			}
		}
		& .right-icon {
			display: inline-block;
			width: 8px;
			height: 12px;
			opacity: 0;
			@include transition;
		}
	}
	&.margin-reset {
		margin: 0;
	}
	&.full-width {
		width: 100%;
		justify-content: center;
	}
	&-disabled {
		border: 1px solid #999999;
		background-color: #cccccc;
		color: #666666;
		cursor: not-allowed;
	}
}
</style>
