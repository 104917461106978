import { Page } from '~/cms/front/core/pages/Page'
import component from './UserProfile.vue'
import { checkPermissions } from '~/authorization/front/utils/checkPermissions'

const Orders = async () => (await import('./Orders')).Orders
const SingleOrder = async () => (await import('./SingleOrder')).SingleOrder
const ChangePasswordForm = async () => (await import('./ChangePasswordForm')).ChangePasswordForm
const ClientDetailsDefault = async () => (await import('./ClientDetails/ClientDetails')).ClientDetails
const ClientDetailsForm = async () => (await import('./ClientDetails/ClientDetailsForm')).ClientDetailsForm

export const UserProfileViews = {
	orders: 'zamowienia',
	'single-order': 'zamowienie',
	'client-change-password': 'zmiana-hasla',
	'client-details': 'dane-klienta',
	'client-details-edit': 'edycja-danych-klienta'
}

class UserProfilePage extends Page {
	static alias = 'user-profile'

	static checkRoute (to) {
		return Object.values(UserProfileViews).includes(to.params.view)
	}

	visible = checkPermissions('client')
	component = component
	view
	ready

	get header () {
		return {
			title: this.app.translator.get('client-profile.hello').replace('<<name>>', this.app.authorization.client?.firstName)
		}
	}

	get viewAlias () {
		return this.route.params.view
	}

	async init () {
		await super.init(...arguments)
		await this.app.translator.prefetch('client-profile')
	}

	async afterRedirect () {
		if (process.client) {
			const getView = {
				[UserProfileViews.orders]: Orders,
				[UserProfileViews['single-order']]: SingleOrder,
				[UserProfileViews['client-change-password']]: ChangePasswordForm,
				[UserProfileViews['client-details']]: ClientDetailsDefault,
				[UserProfileViews['client-details-edit']]: ClientDetailsForm
			}[this.viewAlias]

			if (!getView) return false
			const View = await getView()
			this.view = new View(this.app)
			await this.view.init()
			this.ready = true
		}
	}
}

export { UserProfilePage }
