<template>
	<div class="badge" :class="`${ !type.unit ? type : type.unit}`" v-if="type">
		<div class="badge-content" >
			<base-font variant="price" color="white">
				{{ badgeValue }}
			</base-font>
		</div>
	</div>
</template>

<script>
export default {
	props: {
		type: [String, Object]
	},
	computed: {
		langs () {
			return this.$app.translator.get('products')
		},
		langType () {
			if (!this.langs.type) return ''
			return this.langs.type[this.type]
		},
		badgeValue () {
			if (this.langType) return this.langType
			return `${this.type.value} ${this.type.unit}`
		}
	}
}
</script>

<style lang="scss" scoped>
.badge {
	padding: .5rem 2rem .5rem 2.5rem;
	transform: skew(-15deg);
	z-index: 2;
	margin-left: -5px;
	width: fit-content;
	margin-bottom: 5px;
	&.promotion {
		top: -30px;
		left: -5px;
		background-color: $gold;
		color: $white;
		font-size: 1.4rem;
		font-family: $secondary-font;
		line-height: 2rem;
		padding: .5rem 1rem .5rem 1.5rem;
		&::before {
			position: absolute;
			content: '';
			top: 15px;
			left: 10px;
			height: 2px;
			width: 57px;
			background-color: $white;
			transform: rotate(-5deg);
		}
	}
	&.bestseller {
		background-color: $primary;
		&::before {
			position: absolute;
			content: '';
			top: 0;
			left: 5px;
			height: 100%;
			width: 5px;
			background-color: $gold;
			transform: skew(15deg);
		}
	}
	&.ml, &.g {
		background-color: $primary;
		&::before {
			position: absolute;
			content: '';
			top: 0;
			left: 5px;
			height: 100%;
			width: 5px;
			opacity: 0.8;
			transform: skew(15deg);
		}
	}
	&.new_product {
		background-color: $green-700;
	}
	&.discount {
		background-color: $green-700;
		padding: .5rem 1rem .5rem 1.5rem;
	}
	&-content {
		transform: skew(15deg);
	}
}
</style>
