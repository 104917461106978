<template>
	<section class="newsletter" v-background="$app.layout.value.newsletter.bg_picture.path">
		<base-container>
			<cms-text :props="{variant: 'section-header', color: 'white', tag: 'h2'}" value="newsletter.title" layout/>
			<base-row>
				<base-col sm="12" md="8">
					<div class="newsletter-text-wrapper">
						<cms-text :props="{color: 'white'}" value="newsletter.under_title_text" layout/>
					</div>
					<base-blur darker :blur="!!status">
						<form class="form" v-if="form">
							<div class="form-inner-wrapper">
								<input-template :input="form.inputs.nameNewsletter" />
								<input-template :input="form.inputs.emailNewsletter" autocomplete="email" />
							</div>
							<div class="form-inner-wrapper">
								<input-template :input="form.inputs.privacyNewsletter" />
							</div>

							<button-submit class="newsletter-submit" @click="submit" :loading="loading" type="secondary" :rightSlot="true">
								<cms-text :props="{color: 'white', tag: 'span'}" value="newsletter.btn_text" layout/>
							</button-submit>
						</form>
						<template #status>
							<div class="status" >
								<base-font class="status-text" size="md" family="secondary" color="white">
									{{statusTranslation[status] || statusTranslation.error}}
								</base-font>
							</div>
						</template>
					</base-blur>
				</base-col>
			</base-row>
		</base-container>
	</section>
</template>

<script>
import ButtonSubmit from '~/website/front/components/molecules/ButtonSubmit'

import { Form } from '@f/core/forms/Form'
import { InputTemplate, InputText, InputEmail, InputCheckbox } from '@f/core/forms/inputs'
import { required, email } from 'utils/inputs/validation-front'

export default {
	data () {
		return {
			status: false,
			form: false,
			loading: false
		}
	},
	components: {
		InputTemplate,
		ButtonSubmit
	},
	computed: {
		statusTranslation () {
			return this.$app.translator.get('forms.newsletter.status')
		}
	},
	async mounted () {
		await this.$app.translator.prefetch('errors')
		await this.$app.translator.prefetch('forms.newsletter')
		this.form = new Form()
		this.form.addInput(InputText.create({
			translations: () => this.$app.translator.get('forms.newsletter.inputName'),
			attribute: 'name',
			name: 'nameNewsletter',
			validation: [required()]
		}))
		this.form.addInput(InputEmail.create({
			translations: () => this.$app.translator.get('forms.newsletter.inputEmail'),
			attribute: 'email',
			name: 'emailNewsletter',
			validation: [email()]
		}))
		this.form.addInput(InputCheckbox.create({
			translations: () => this.$app.translator.get('forms.newsletter.inputPolice'),
			attribute: 'police',
			name: 'privacyNewsletter',
			validation: [required()]
		}))
	},
	methods: {
		async submit () {
			this.status = ''
			if (!await this.form.validate()) return false
			this.loading = true
			const { name, email } = this.form.getValues()
			const status = await this.$app.getService('rext').addSubscriber({ name, email })
			this.loading = false
			this.status = status

			if (status === 201) this.$app._emit('newsletter:subscriber:new')
		}
	},
	watch: {
		$route () {
			this.form.hideErrors()
		}
	}
}
</script>
<style lang="scss" scoped>
.newsletter {
	position: relative;
	background-size: cover;
	overflow: hidden;
	min-height: 560px;
	@include media-breakpoint-up(lg) {
		padding: 0 0 2rem 0;
		width: 100%;
		min-height: 633px;
		overflow: hidden;
	}
	&:deep(*) {
		z-index: $level1;
	}
	&-letter-img :deep(img) {
		margin-bottom: -3px;
		@include media-breakpoint-landscape() {
			display: none;
		}
		@include media-breakpoint-up(md) {
			position: absolute;
			bottom: 3px;
			right: -80px;
		}
		@include media-breakpoint-up(xl) {
			right: -110px;
		}
		@include media-breakpoint-up(xxl) {
			right: 60px;
		}
	}
	&-text-wrapper {
		margin-bottom: 3rem;
	}
	.form {
		&-inner-wrapper {
			display: flex;
			flex-direction: column;
			& :deep(.input:not(:last-child))  {
				margin-right: 3rem;
			}
			@include media-breakpoint-up(md) {
				flex-direction: row;
			}
		}
		:deep(.button-submit .button)  {
			width: 100%;
			@include media-breakpoint-up(md) {
				width: auto;
			}
		}
	}
	.status {
		& :deep(.status-text)  {
			color: $white;
		}
	}
}
</style>
