<template>
	<div class="default-layout-wrapper" :class="{ 'global-discount-layout': $app.layout.showGlobalDiscount }">
		<base-global-discount v-if="$app.layout.showGlobalDiscount" />
		<base-menu :menuItems="$app.layout.value.menu.items"/>
		<shopping-cart/>
		<main class="content">
			<base-header v-if="$app.page.header"/>
			<slot />
		</main>
		<base-newsletter v-if="!isPageACartPage"/>
		<!-- <base-cookie/> -->
		<base-footer/>
		<base-copyright class="default-layout-footer" link="https://www.rekinysukcesu.pl/"/>
	</div>
</template>

<script>
import BaseMenu from '~/website/front/components/organisms/BaseMenu'
import BaseNewsletter from '~/website/front/components/sections/BaseNewsletter'
// import BaseCookie from '~/website/front/components/organisms/BaseCookie'
import BaseFooter from '~/website/front/components/sections/BaseFooter'
import BaseHeader from '~/website/front/components/molecules/BaseHeader'
import BaseCopyright from '~/website/front/components/sections/BaseCopyright'
import ShoppingCart from '~/shop/front/components/ShoppingCart'
import BaseGlobalDiscount from '~/website/front/components/organisms/BaseGlobalDiscount/BaseGlobalDiscount'
import { pageMixin } from '~/website/front/core/pages/page.mixin'

export default {
	components: {
		BaseMenu,
		ShoppingCart,
		BaseCopyright,
		BaseNewsletter,
		BaseFooter,
		// BaseCookie,
		BaseHeader,
		BaseGlobalDiscount
	},
	computed: {
		isPageACartPage () {
			return this.$app.page.alias === 'cart-page'
		}
	},
	mixins: [
		pageMixin
	]
}
</script>
<style lang="scss" scoped>

.default-layout-wrapper {
	padding-top: 8.5rem;
	display: flex;
	flex-direction: column;
	min-height: 100vh;
	@include media-breakpoint-up(lg) {
		padding-top: 10.5rem;
	}
}
.global-discount-layout {
	@include media-breakpoint-up(lg) {
		padding-top: 15.5rem;
	}
}
.default-layout-footer {
	margin-top: auto;
}
</style>
