<template>
	<section class="copyright">
		<div class="inner-wrapper">
			<cms-text value="copyright_text" :props="{ color: 'primary', tag: 'span'}" layout/>
			<span> - realizacja sklepu internetowego:</span>
			<base-link underline type="gold" url="https://www.rekinysukcesu.pl/">
				Agencja marketingowa Wrocław - RekinySukcesu.pl
			</base-link>
		</div>
	</section>
</template>
<script>

export default {
	props: {
		link: {
			type: String
		}
	}
}
</script>
<style lang="scss" scoped>
.copyright {
	display: flex;
    justify-content: center;
    align-items: center;
	flex-direction: column;
	padding: 1.6rem 1rem 4rem;
	text-align: center;
	color: $primary;
	font-size: 1.4rem;
	& * {
		display: inline;
		text-align: left;
	}
	@include media-breakpoint-up(md) {
		font-size: 1.6rem;
		flex-direction: row;
		padding: 4rem;
	}
	@include media-breakpoint-up(lg){
		padding: 4rem 4rem 6rem 4rem;
	}
	@include media-breakpoint-up(xl){
		padding: 4rem;
	}
	.inner-wrapper {
		text-align: center;
		@include media-breakpoint-up(md) {
			text-align: left;
		}
	}
	& a {
		color: $gold;
	}
}
</style>
