<template>
	<li class="nav-item">
		<base-link
			:type="false"
			:to="item.to"
			class="nav-link"
			:class="{'active': this.item.to.includes(this.$route.meta.alias)}"
		>
			{{item.name}}
		</base-link>
	</li>
</template>
<script>

export default {
	props: {
		item: {
			type: Object
		}
	}
}
</script>
<style lang="scss" scoped>
.nav {
	&-item {
		display: block;
		text-transform: uppercase;
		@include media-breakpoint-up(xl) {
			margin-right: 2.5rem;
		}
		@include media-breakpoint-up(xxl) {
			margin-right: 5rem;
		}
	}
	&-link {
		position: relative;
		color: $primary;
		font-weight: 500;
		text-decoration: none;
		display: block;
		padding: 2rem 0 2rem 3rem;
		border-bottom: 1px solid $gray-300;
		&::before {
			content: '';
			position: absolute;
			left: 0;
			top: 0;
			height: 100%;
			width: 10px;
			background-color: $gold;
			opacity: 0;
			transition: .3s ease;
		}
		&.active {
			&::before {
				opacity: 1;
			}
		}
		@include media-breakpoint-up(xl) {
			border: none;
			padding: 2rem 0 1rem 0;
			margin-bottom: 0;
			&::before {
				top: inherit;
				bottom: 0;
				left: -10%;
				width: 120%;
				height: 2px;
				background-color: $gold;
			}
			&:hover {
				&::before {
					opacity: 1;
				}
			}
		}
	}
}
</style>
