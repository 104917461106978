import { DefaultLayout } from './core/layouts/DefaultLayout'
import { rextServiceExtend } from './core/services/RextService'
import registerGlobalComponents from './components/_globals'
import { BlankLayout } from './core/layouts/BlankLayout'
import { SimplifiedLayout } from './core/layouts/SimplifiedLayout'
import { ContainerDefaultLayout } from '~/website/front/core/layouts/ContainerDefaultLayout'
const FaqPage = async () => (await import('./core/pages/FaqPage')).FaqPage
const ContactPage = async () => (await import('./core/pages/ContactPage')).ContactPage
const DefaultPage = async () => (await import('./core/pages/DefaultPage')).DefaultPage
const NotFoundPage = async () => (await import('./core/pages/NotFoundPage')).NotFoundPage
const TextSection = () => import('~/website/front/sections/TextSection/')
const ContactForm = () => import('~/website/front/sections/ContactForm/')
const AnimationSection = () => import('~/website/front/sections/AnimationSection/')
const Slider = () => import('~/website/front/sections/Slider/')
const PhotoSection = () => import('~/website/front/sections/PhotoSection/')
const TextContent = () => import('~/website/front/sections/TextContent/')
const CardSection = () => import('~/website/front/sections/CardSection/')
const GallerySection = () => import('~/website/front/sections/GallerySection/')
const Subtitle = () => import('~/website/front/sections/Subtitle/')
const Buttons = () => import('~/website/front/sections/Buttons/')
const CTA = () => import('~/website/front/sections/CTA/')
const ParagraphCard = () => import('~/website/front/sections/ParagraphCard/')

class WebsiteModule {
	sections = []

	constructor (app) {
		this.app = app
		this.app.sections = {}
		this.app.addSection = (alias, component) => {
			this.app.sections[alias] = component
		}
	}

	afterRouterCreated (app) {
		app.router.afterEach(async (to, from) => {
			if (process.client && from.meta.alias) window.scrollTo(0, 0)
		})
	}

	readyToAddPages (app) {
		app.addPage('not-found', NotFoundPage)
		app.addPage('default', DefaultPage)
		app.addPage('faq', FaqPage)
		app.addPage('contact', ContactPage)
	}

	readyToAddLayouts (app) {
		app.addLayout(DefaultLayout)
		app.addLayout(BlankLayout)
		app.addLayout(SimplifiedLayout)
		app.addLayout(ContainerDefaultLayout)
	}

	beforeInitReactive (app) {
		registerGlobalComponents(app)
		app.addSection('animation_section', AnimationSection)
		app.addSection('contact_form', ContactForm)
		app.addSection('text', TextSection)
		app.addSection('slider', Slider)
		app.addSection('subtitle', Subtitle)
		app.addSection('buttons', Buttons)
		app.addSection('text_content', TextContent)
		app.addSection('card_section', CardSection)
		app.addSection('photo_section', PhotoSection)
		app.addSection('gallery_section', GallerySection)
		app.addSection('cta', CTA)
		app.addSection('paragraph_card', ParagraphCard)
	}

	readyToExtendServices (app) {
		app.extendService('rext', rextServiceExtend)
	}
}
export { WebsiteModule }
