import { rextServiceExtend } from './core/services/RextService'
const ArticlePage = async () => (await import('./core/pages/Article')).ArticlePage
const ArticleListPage = async () => (await import('./core/pages/ArticleList')).ArticleListPage
const NewestBlogPosts = () => import('./sections/NewestBlogPosts')
const ContentSection = () => import('./sections/ContentSection')
const ArticlePhotoSection = () => import('./sections/ArticlePhotoSection')

class BlogModule {
	readyToExtendServices (app) {
		app.extendService('rext', rextServiceExtend)
	}

	readyToAddPages (app) {
		app.addPage('article', ArticlePage)
		app.addPage('article-list', ArticleListPage)
	}

	beforeInitReactive (app) {
		app.addSection('newest_blog_posts', NewestBlogPosts)
		app.addSection('content_section', ContentSection)
		app.addSection('article_photo_section', ArticlePhotoSection)
	}
}

export { BlogModule }
