<template>
	<div class="default-container-layout-wrapper">
		<base-menu v-if="$app.layout.value.menu.items" :menuItems="$app.layout.value.menu.items"/>
		<shopping-cart/>
		<main class="content">
			<base-container class="content-container">
				<base-header v-if="$app.page.header"/>
				<slot />
			</base-container>
		</main>
		<base-newsletter/>
		<!-- <base-cookie/> -->
		<base-footer/>
		<base-copyright class="default-layout-footer" link="https://www.rekinysukcesu.pl/"/>
	</div>
</template>

<script>
import BaseMenu from '~/website/front/components/organisms/BaseMenu'
import BaseNewsletter from '~/website/front/components/sections/BaseNewsletter'
// import BaseCookie from '~/website/front/components/organisms/BaseCookie'
import BaseFooter from '~/website/front/components/sections/BaseFooter'
import BaseHeader from '~/website/front/components/molecules/BaseHeader'
import BaseCopyright from '~/website/front/components/sections/BaseCopyright'
import ShoppingCart from '~/shop/front/components/ShoppingCart'
import { pageMixin } from '~/website/front/core/pages/page.mixin'

export default {
	components: {
		BaseMenu,
		ShoppingCart,
		BaseCopyright,
		BaseNewsletter,
		BaseFooter,
		// BaseCookie,
		BaseHeader
	},
	mixins: [
		pageMixin
	]
}
</script>

<style lang="scss" scoped>

.default-container-layout-wrapper {
	padding-top: 8.5rem;
	display: flex;
	flex-direction: column;
	min-height: 100vh;
	@include media-breakpoint-up(lg) {
		padding-top: 10.5rem;
	}
}
.default-layout-footer {
	margin-top: auto;
}
.content {
	&:last-of-type {
		margin-bottom: 12rem;
	}
}
::v-deep .content-container {
	.container {
		padding: 0;
	}

	.content-section {
		max-width: 100%;
	}
}
</style>
