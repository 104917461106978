export function checkPermissions (...permissions) {
	return function (app) {
		if (!app.authorization.client) return false
		const clientPerms = app.authorization.client.user.permissions
		return permissions.every(permission => {
			if (!~clientPerms.indexOf(permission)) return false
			return true
		})
	}
}
