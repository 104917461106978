
<template>
	<a
		:href="path"
		:class="classes"
		@click.prevent="handleClick()"
		:target="target"
	>
		<slot />
		<template v-if="type === 'product-info'">
			<base-svg name="link_info_icon"/>
		</template>
	</a>
</template>

<script>

import BaseSvg from '@f/components/BaseSvg'
import { NavigationMixin } from '../../mixins/NavigationMixin'

export default {
	components: {
		BaseSvg
	},
	computed: {
		classes () {
			if (!this.type) return false
			return [
				'link',
				`link-${this.type}`,
				this.underline ? 'underline' : 'no-underline'
			]
		}
	},
	mixins: [NavigationMixin],
	props: {
		type: {
			type: [String, Boolean],
			default: 'gold'
		},
		underline: {
			type: Boolean,
			default: false
		}
	}
}
</script>
