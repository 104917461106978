<template>
	<base-font
		v-bind="props"
		:html="html"
		:contenteditable="editable"
		@blur="saveValue"
	/>
</template>

<script>
import { getValue, setValue } from 'utils/objects'
export default {
	props: {
		value: String,
		layout: Boolean,
		props: Object
	},
	computed: {
		html () {
			let value
			if (this.layout) value = this.$app.layout.value
			else value = this.$app.page.value

			return getValue(this.value, value, '')
		},
		mode () {
			if (this.layout) return 'layout'
			return 'page'
		},
		editable () {
			return this.$app.wyswig.mode === this.mode
		}
	},
	methods: {
		saveValue (e) {
			if (!this.editable) return false
			let wrapper = this.$app.page
			if (this.layout) wrapper = this.$app.layout
			setValue(this.value, wrapper.value, e.target.innerHTML)
		}
	}
}
</script>
