class Review {
	static async fetch ({ app, id }) {
		const service = app.getService('rext')
		const data = await service.getProductReviews(id)
		if (data.error) return []

		return {
			reviews: data.reviews.map(item => Review.create(item))
		}
	}

	static create (data) {
		const instance = new Review()
		Object.entries(data).forEach(([property, value]) => {
			if (property in instance) instance[property] = value
		})
		return instance
	}

	id
	author
	content
	rate
	status
}

export { Review }
