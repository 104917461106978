<template>
	<div class="field-quantity">
		<input
			class="field-quantity-input"
			v-model="model"
			ref="field-input"
			type="number"
			@focus="setFocus(true)"
			@blur="blurHandler"
			min="1"
			v-if="renderTypeInput"
		/>
		<select
			class="field-quantity-select"
			v-model="model"
			@change="changeFieldType($event)"
			@focus="setFocus(true)"
			v-click-outside="setFocusFalse"
			v-else
		>
			<option
				class="field-quantity-option"
				:value="i"
				:key="i"
				v-for="i in 9"
			>
				{{ i }}
			</option>
			<option class="field-quantity-option-max" :value="10">+10</option>
		</select>
	</div>
</template>

<script>
import ClickOutside from 'vue-click-outside'

const fieldTypes = {
	SELECT: 'select',
	INPUT: 'input'
}

export default {
	props: {
		value: {
			type: Number,
			default: 1
		}
	},
	data () {
		return {
			fieldType: fieldTypes.SELECT,
			model: this.value,
			focused: false
		}
	},
	computed: {
		renderTypeInput () {
			return this.fieldType === fieldTypes.INPUT
		}
	},
	methods: {
		async changeFieldType (event) {
			this.setFocus(false)
			if (Number(event.target.value) !== 10) return
			this.fieldType = fieldTypes.INPUT
			await this.$nextTick()
			this.$refs['field-input'].focus()
		},
		setFocus (state) {
			this.focused = state
			this.$emit('is-focused', state)
		},
		setFocusFalse () {
			this.setFocus(false)
		},
		blurHandler () {
			if (this.model !== this.value) this.model = this.value
			this.setFieldType()
			this.setFocusFalse()
		},
		setFieldType () {
			if (this.model < 10) this.fieldType = fieldTypes.SELECT
			else this.fieldType = fieldTypes.INPUT
		}
	},
	mounted () {
		this.setFieldType()
	},
	watch: {
		model (currentValue) {
			this.$emit('input', Number(currentValue))
		},
		value (currentValue) {
			this.model = currentValue
			if (!this.focused) this.setFieldType()
		}
	},
	directives: {
		ClickOutside
	}
}
</script>

<style lang="scss" scoped>
.field-quantity {

	&-input {
		padding: 5px;
		width: 60px;
		text-align: center;
	}
	&-select {
		padding: 5px;
		width: 60px;
		@include media-breakpoint-up(xl) {
			cursor: pointer;
		}
	}
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}
</style>
