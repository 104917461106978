<template>
		<div class="logo-wrapper" :class="{active: activeMobileNav, 'show-menu': scrollDirection === 'down'}">
			<div class="logo-link" :class="{ 'redirect-available': redirectToHomepageAvailable }" @click="redirect">
				<base-svg class="navbar-logo menu-logo" name="nimphy_logo"/>
			</div>
			<product-search class="menu-search" :scrollDirection="scrollDirection" />
			<base-cart class="menu-cart"/>
			<button
				@click.stop="$emit('menuButtonClick')"
				class="mobile-button"
			>
				<div class="mobile-button-box">
					<div
						class="mobile-button-inner-wrapper"
						:class="{active: activeMobileNav}"
					></div>
				</div>
			</button>
		</div>
</template>
<script>
import { getPath } from 'utils/getPath'
import BaseCart from '../../../molecules/BaseCart'
import ProductSearch from '~/shop/front/components/ProductSearch/ProductSearch'

export default {
	components: {
		BaseCart,
		ProductSearch
	},
	data: () => ({
		scrollPosition: 0,
		scrollDirection: 'down'
	}),
	props: {
		activeMobileNav: {
			type: Boolean
		}
	},
	computed: {
		redirectToHomepageAvailable () {
			const noRedirectPageAliases = [
				'homepage',
				'cart-page'
			]
			const pageAlias = this.$app.page.alias
			if (noRedirectPageAliases.includes(pageAlias)) return false
			return true
		}
	},
	methods: {
		toggleMenu (e) {
			const distanceFromTop = window.scrollY
			if (this.scrollPosition < 200) {
				this.scrollDirection = 'down'
				this.scrollPosition = distanceFromTop
			} else if (!this.activeMobileNav && this.$app.utils.mobile) {
				this.scrollDirection = distanceFromTop > this.scrollPosition ? 'up' : 'down'
				this.scrollPosition = distanceFromTop
			}
		},
		redirect () {
			if (!this.redirectToHomepageAvailable) return false
			this.$app.vue.$router.push(getPath({
				app: this.$app,
				to: 'homepage'
			}))
		}
	},
	mounted () {
		window.addEventListener('scroll', this.toggleMenu)
	},
	beforeDestroy () {
		window.removeEventListener('scroll', this.toggleMenu)
	}
}
</script>
<style lang="scss" scoped>
.logo-wrapper {
	position: fixed;
	width: 100%;
	height: $menuHeight;
	top: 0;
	left: 0;
	display: flex;
	justify-content: space-between;
	align-items: center;
	background-color: $gray-400;
	padding: 0 2rem 0 1.5rem;
	z-index: $level2;
	transition: height 0.4s ease-in;
	border-bottom: 1px solid $gray-500;
	transform: translateY(-100%);
	transition: .3s ease-in;
	@include media-breakpoint-up(xl) {
		transform: translateY(0)
	}
	:deep(.menu-logo) {
		width: 246px;
		@include media-breakpoint-up(lg) {
			width: 210px;
		}
		@include media-breakpoint-up(xl) {
			width: 170px;
		}
	}
	&.show-menu {
		transform: translateY(0)
	}
	&::before {
		content: "";
		position: absolute;
		left: 0;
		bottom: -9px;
		height: 10px;
		width: 100%;
		z-index: -34;
		opacity: 0;
		transition: opacity 0.4s ease-in;
		background-color: #dedede;
	}
	&.active {
		height: 167px;
		@include media-breakpoint-landscape() {
			height: 100px;
		}
		&::before {
			opacity: 1;
		}
	}
	@include media-breakpoint-up(xl) {
		position: static;
		width: auto;
		height: 105px;
		padding: 0;
		z-index: auto;
	}
	.logo-link {
		min-height: 44px;
		@include media-breakpoint-up(lg) {
			min-height: 58px;
		}
		@include media-breakpoint-up(xl) {
			min-height: 41px;
		}
		&.redirect-available {
			cursor: pointer;
		}
	}
	.menu-search {
		display: block;
		position: absolute;
		top: 30px;
		right: 90px;

		@include media-breakpoint-up(xl) {
			display: none;
		}
	}
	.menu-cart {
		display: block;
		position: absolute;
		top: 34px;
		right: 65px;
		transition: .3s ease-in;
		@include transition();

		@include media-breakpoint-up(xl) {
			display: none;
		}
	}
	&.active {
		& :deep(.navbar-logo)  {
			transform: scale3d(1, 1, 1) translate(0px, 10px);
			opacity: 1;
		}

	}
	:deep(.navbar-logo)  {
		transform: translateZ(0) scale3d(0.65,0.65,0.65) translateX(-60px);
		backface-visibility: hidden;
		opacity: 1;
		transition: opacity 0.4s ease-in, transform 0.4s ease-in;
		@include media-breakpoint-up(lg) {
			transform: translateZ(0)  scale3d(1,1,1) translateX(10px);
			opacity: 1;
		}
		@include media-breakpoint-up(xl) {
			transform: translateZ(0)  scale3d(1,1,1) translateX(0);
		}
	}
}
.mobile-button {
	position: absolute;
	top: 30px;
	right: 20px;
	display: inline-block;
	margin: 0;
	cursor: pointer;
	transition: opacity, filter .3s linear;
	border: 0;
	outline: none;
	background-color: transparent;
	-webkit-tap-highlight-color: transparent;
	@include media-breakpoint-up(xl) {
		display: none;
	}
	&-box {
		position: relative;
		display: inline-block;
		width: 23px;
		height: 26px;
	}
	&-inner-wrapper {
		top: 2px;
		&.active {
			transform: translate3d(0, 10px, 0) rotate(45deg);
			&:after {
				transform: translate3d(0,-16px,0) rotate(-90deg);
			}
			&:before {
				transform: rotate(-45deg) translate3d(-5.71429px,-6px,0);
				opacity: 0;
			}
		}
		&,
		&::before,
		&::after {
			position: absolute;
			width: 23px;
			height: 4px;
			transition: transform .3s ease;
			border-radius: 4px;
			background-color: $dark-600;
		}
		&::before,
		&::after {
			display: block;
			content: '';
		}
		&::after {
			top: 1.6rem;
		}
		&::before {
			top: 8px;
			transition: transform, opacity .3s ease;
		}
	}
}

</style>
<style>
.safari .navbar-wrapper .logo-wrapper .menu-cart {
	right: 75px;
}
</style>
