export function rextServiceExtend (Service) {
	return class RextService extends Service {
		async addSubscriber ({ name, email }) {
			const http = await this.getHttpWithRecaptcha()
			const { status } = await http.post('/newsletter/subscriber', {
				name,
				email
			})
			const { profileRes } = await http.post('/klaviyo/profile', {
				first_name: name,
				last_name: '',
				email: email
			})
			const { eventRes } = await http.post('klaviyo/event/newsletter', {
				email
			})

			if (status === 400) return { error: true }
			if (profileRes === 400) return { error: true }
			if (eventRes === 400) return { error: true }
			return status
		}
	}
}
