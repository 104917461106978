<template>
	<div class="button-submit">
		<base-button class="button" @click="$emit('click')" :disabled="isDisabled" :type="type" :fullWidth="fullWidth">
			<base-svg v-if="loading" name="autorenew" />
			<slot v-else/>
		</base-button>
	</div>
</template>

<script>

export default {
	inheritAttrs: false,
	props: {
		loading: Boolean,
		disabled: Boolean,
		type: String,
		fullWidth: Boolean
	},
	computed: {
		isDisabled () {
			if (this.disabled) return true
			return this.loading
		}
	}
}
</script>

<style lang="scss" scoped>
.button-submit {
	display: flex;
	align-items: center;
	padding-bottom: 1rem;
	.button {
		display: flex;
		justify-content: center;
		text-align: center;
		min-width: 210px;
		height: 61px;
		margin-top: 0;
		padding: 1.5rem 1.5rem;
		& :deep(span) {
			font-size: 1.5rem;
		}
		@include media-breakpoint-up(lg) {
			min-width: 270px;
			margin-top: 2rem;
			padding: 1.5rem 3rem;
		}
		@include media-breakpoint-up(xl) {
			min-width: 330px;
			margin-top: 2rem;
			padding: 2rem 6rem;
		}
	}
	svg {
		animation: rotate infinite 1s linear;
		fill: $white;
		margin-left: 5px;
		width: 25px;
	}
	@keyframes rotate {
		from {transform: rotate(0);}
		to {transform: rotate(360deg);}
	}
}
</style>
