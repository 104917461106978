class Category {
	static async fetch ({ app }) {
		const service = app.getService('rext')
		const data = await service.getCategories()

		if (data.error) return []
		return data.map(item => Category.create(item))
	}

	static create (data) {
		const instance = new Category()
		Object.entries(data).forEach(([property, value]) => {
			if (property in instance) instance[property] = value
		})
		return instance
	}

	id
	name
}

export { Category }
