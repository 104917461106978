import { render, staticRenderFns } from "./MenuLogoWrapper.vue?vue&type=template&id=765475c1&scoped=true&"
import script from "./MenuLogoWrapper.vue?vue&type=script&lang=js&"
export * from "./MenuLogoWrapper.vue?vue&type=script&lang=js&"
import style0 from "./MenuLogoWrapper.vue?vue&type=style&index=0&id=765475c1&prod&lang=scss&scoped=true&"
import style1 from "./MenuLogoWrapper.vue?vue&type=style&index=1&id=765475c1&prod&lang=css&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "765475c1",
  null
  
)

export default component.exports